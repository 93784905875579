<template>
	<el-cascader v-model="val" :props="options" clearable filterable @change="setValue"></el-cascader>
</template>

<script>
	import { ref, watch, getCurrentInstance } from "vue";
	export default {
		name: 'ecascader',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// mode : '',//模式 city
					// labelKey: '',显示字段名 默认label
					// valueKey: '',实际值字段名 默认value
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
					// placeholder:'' 提示语
					// tree:false,//true，返回id的tree
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const config = props.config || {}
			let defaultConfig = {
				'city': {}//省市县
			}
			var setting = Object.assign({}, defaultConfig[config.mode], config)
			var val = ref(proxy.modelValue ? proxy.modelValue.split(',') : [])

			watch(props, (newValue, oldValue) => {
				// console.log(newValue,oldValue,newValue.value!=oldValue.value);
				// if (!newValue.modelValue) {//清空
				// 	val.value = []
				// }else 
				if (!val.value || !val.value.length) {
					val.value = !newValue.modelValue ? [] : newValue.modelValue.split(',')
				}
			});
			const loadData = (node, resolve) => {
				if (!setting.url) return;
				let q = proxy.$isFunc(setting.params) ? setting.params(node) : setting.params;
				q = Object.assign({ pid: node?.value }, q)
				proxy.$post(setting.url, q, ({ data }) => {
					if (proxy.$isEmpty(data)) {
						resolve([]);
						return;
					}
					if (proxy.$isFunc(setting.filter)) {
						data = setting.filter(data);
					}
					data.forEach(item => {
						item.leaf = !!item[setting.leafKey || 'leaf']
						item.label = item[setting.labelKey || 'label']
						item.value = item[setting.valueKey || 'value'] + ''
					});
					resolve(data);
				});
			}
			//init
			const options = ref({ lazy: true, lazyLoad: loadData })

			const setValue = (value) => {
				var v = ''
				if (config.tree) {
					v = value ? value.join() : ''
				} else {
					v = value ? value[value.length - 1] : ''
				}
				emit('update:modelValue', v);
				emit('set', { value });
			}
			return { val, options, setValue }
		}
	};
</script>