<template>
	<el-time-picker v-model="val" clearable :placeholder="config.placeholder" @change="setValue"  format="HH:mm" value-format="HH:mm">
	</el-time-picker>
</template>

<script>
	export default {
		name: 'etime2',
		props: {
			value: {},
			config: {
				type: Object,
				default: {
					// options: {},参数
					// placeholder: '',
				}
			}
		},
		data() {
			return {
				val: '',
			};
		},
		created() {
			this.init();
		},
		watch: {
			value(value) {
				if (!value) {
					this.val = '';
				}
			}
		},
		methods: {
			init() {
			},
			setValue(value) {
				this.$emit('update:modelValue', value);
				this.$emit('change', { value: value });
			}
		}
	};
</script>