<template>
	<el-input ref="inp" :type="opt.type" :placeholder="opt.placeholder" :clearable="false" :maxlength="opt.maxlength"
		v-model.trim="val" :disabled="disabled" @keyup.enter.native="keyup($event, index)" @change="setValue">
		<template #append v-if="append">
			<span v-text="append"></span>
		</template>
	</el-input>
</template>

<script>
	export default {
		name: 'etext',
		props: {
			modelValue: String,
			disabled: Boolean,
			append: String,
			config: {
				type: Object,
				default: {
				}
			}
		},
		// watch: {
		// 	modelValue(old, val) {
		// 		if (val) {
		// 			this.$emit('update:modelValue', val);
		// 		}
		// 	}
		// },
		data() {
			return { val: '', opt: {} }
		},
		created() {
			this.val = this.modelValue
		},
		methods: {
			keyup(e) {
				if (e.keyCode == 13) {
					this.$emit('k13');
				}
			},
			focus() {
				this.$refs.inp.focus()
			},
			clear() {
				this.val = ''
			},
			setValue() {
				var value = this.val
				this.$emit('update:modelValue', value);
				this.$emit('set', { value });
			}
		}
	};
</script>

<style lang="css" scoped></style>