import { createStore } from 'vuex'

export default createStore({
  state: {//数据
    tabs: [],
    menuIndex: '',//当前菜单
    menuCollapse: !!localStorage.getItem('menus_collapse'),//左侧菜单是否展开
    tabActive: ''//当前tab
  },
  getters: {//计算属性
  },
  mutations: {//同步方法 通过commit调用
    addTab(state, tab, clear) {
      return
      if (!tab.meta || !tab.meta.title) return
      if (clear) {
        state.tabs = []
      }
      var t = { title: tab.meta.title, path: tab.path, name: tab.path.replaceAll('/', '') || 'main' }
      let index = state.tabs.findIndex(c => { return c.name == tab.name })
      if (index >= 0) {
        state.tabs.splice(index, 1, t)
      } else {
        state.tabs.push(t)
      }

      var cur = window.$menus.find(c => c.name == t.name)
      if (cur) {
        state.menuIndex = cur.id
      } else {
        state.menuIndex = ''
      }

      state.tabActive = t.name
    },
    removeTab(state, name) {
      let index = state.tabs.findIndex(c => { return c.name == name })
      if (index >= 0) {
        state.tabs.splice(index, 1)
      }
    },
    clearTab(state) {
      state.tabs = []
    },
    menuCollapse(state) {
      state.menuCollapse = !state.menuCollapse
      localStorage.setItem('menus_collapse', state.menuCollapse ? 1 : '')
    }
  },
  actions: {//异步方法 通过dispatch调用
    addTab({ commit }, tab, clear) {
      commit('addTab', tab, clear)
    }
  },
  modules: {//默认模块 state区分命名空间，其他不区分
  }
})
