<template>
    <el-upload v-if="!pro" ref="uploadRef" action="" :auto-upload="false" :multiple="false" :accept="s.accept"
        :on-change="upload" limit="1" :show-file-list="false">
        <template #trigger>
            <el-button type="primary">选择文件</el-button>
        </template>
        <div v-text="tips" style="margin-top: 10px;color: #927373;"></div>
    </el-upload>
    <div v-else>
        文件上传中...
        <el-progress :text-inside="true" :stroke-width="15" :percentage="pro" />
    </div>
</template>

<script>
    import { ref, getCurrentInstance } from "vue";
    export default {
        name: 'euploadFile',//上传文件
        props: {
            modelValue: {},
            config: {
                type: Object,
                default: {
                    //type:文件类型 accept: '',支持的文件类型 size:'500kb',支持的文件大小
                }
            }
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const config = props.config || {}
            const uploadRef = ref(null)
            let s = proxy.$config.upload[config.type || 'img']
            const pro = ref(0)
            const tips = `支持${s.accept}格式文件，且文件小于${s.size}`

            const upload = (file) => {
                proxy.$uploadChunk(s, file.raw, ({ success, msg, data, progress }) => {
                    if (progress) {
                        pro.value = progress
                    } else if (success) {
                        pro.value = 0
                        emit('upload', { path: proxy.getPath(data.path) })
                    } else {
                        pro.value = 0
                        proxy.$err(msg);
                    }
                })
            }

            return { tips, s, pro, upload, uploadRef }
        }
    };
</script>
<style scoped>

</style>