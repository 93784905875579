<template>
	<div v-for="(src,index) in res" :key="index" class="upload-imgs" :class="className">
		<el-image :src="src" :preview-src-list="res" :initial-index="index" lazy preview-teleported hide-on-click-modal
			fit="cover" :style="{height: h,width: w}">
		</el-image>
		<i class="iconfont icon-jianhao" @click="remove(index)" title="删除文件"></i>
	</div>
	<el-upload ref="uploadRef" action="" :auto-upload="false" :multiple="false" :accept="s.accept" :on-change="upload"
		:show-file-list="false" v-if="limit>res.length">
		<template #trigger>
			<div style="background: #efefef;text-align: center;cursor: pointer;box-sizing: border-box;" :title="tips"
				:style="{height: h,width: w,paddingTop: ptop}">
				<i class="iconfont icon-shangchuan"></i>
			</div>
		</template>
	</el-upload>
</template>

<script>
	import { ref, watch, getCurrentInstance } from "vue";
	export default {
		name: 'eupload',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					//limit:'',//文件上限
					//type:文件类型 accept: '',支持的文件类型 size:'500kb',支持的文件大小
					//tips
					//style
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const config = props.config || {}
			const uploadRef = ref(null)
			let _cfg = proxy.$config.upload
			let s = Object.assign({}, _cfg.img, config.type)

			let className = ''
			let w = '80px'
			let h = '80px'
			let ptop = '20px'
			if (config.style == 'small') {
				w = '30px'
				h = '30px'
				ptop = '5px'
				className = 'upload-imgs-xs'
			}

			var res = ref([])
			const initValue = () => {
				if (proxy.modelValue) {
					let v = proxy.modelValue
					v = (proxy.$isArr(v) ? v : (v + '').split(',')).map(c => proxy.getPath(c))
					res.value = v
				} else {
					res.value = []
				}
				uploadRef.value && uploadRef.value.clearFiles()
			}
			watch(props, initValue);
			initValue()

			const limit = config.limit || 5
			const tips = `支持${s.accept}格式文件，且文件小于${s.size}`
			const upload = (file) => {
				proxy.$upload(s, file.raw, ({ success, msg, data }) => {
					if (success) {
						res.value.push(proxy.getPath(data.path))
						setVlaue()
					} else {
						proxy.$err(msg);
					}
				})
			}
			const remove = (index) => {
				res.value.splice(index, 1)
				setVlaue()
			}
			const setVlaue = (file) => {
				var arr = res.value.map(c => proxy.replacePath(c))
				proxy.$emit('update:modelValue', arr.join())
				proxy.$emit('set', { value: arr })
			}

			return { w, h, ptop, className, res, limit, s, tips, upload, remove, uploadRef }
		}
	};
</script>
<style scoped>
	.upload-imgs {
		border: 1px solid #e0e0e0;
		margin-right: 10px;
		width: 82px;
		height: 82px;
		position: relative;
		box-sizing: border-box;
	}

	.upload-imgs .iconfont {
		font-size: 14px;
		position: absolute;
		top: -8px;
		right: -8px;
		cursor: pointer;
		color: #000;
		z-index: 2;
		line-height: 14px;
		padding: 2px;
		border: 1px solid #e0e0e0;
		border-radius: 100%;
		background: #fff;
		text-align: center;
	}

	.upload-imgs-xs {
		width: 32px;
		height: 32px;
	}

	.upload-imgs-xs .iconfont {
		top: -6px;
		right: -8px;
	}

	.upload-imgs-xs .el-image {
		margin: 0;
	}
</style>