<template>
	<el-form ref="formRef" :model="model" :rules="rules" :label-width="labelWidth" :label-position="labelPosition"
		:label-suffix="labelSuffix" :inline="inline" :size="size" @submit.native.prevent style="padding-right: 10px;"
		:class="class">
		<el-form-item :label="item.label" :prop="item.field" v-for="(item, index) in fields" :key="item.field">
			<!-- <el-select v-if="item.type == 2 && item.affect.flag" v-model="model[item.field]"
				:placeholder="item.affect.tips"></el-select> -->
			<eselect v-if="item.type == 2" :config="item.opt" v-model="model[item.field]" @set="change(item)">
			</eselect>
			<eselectTable v-else-if="item.type == 201" :config="item.opt" v-model="model[item.field]">
			</eselectTable>
			<eselectTree v-else-if="item.type == 202" :config="item.opt" v-model="model[item.field]"
				@set="change(item)"></eselectTree>
			<eradio v-else-if="item.type == 3" :config="item.opt" v-model="model[item.field]"></eradio>
			<echeckbox v-else-if="item.type == 4" :config="item.opt" v-model="model[item.field]"></echeckbox>
			<eswitch v-else-if="item.type == 5" :config="item.opt" v-model="model[item.field]"></eswitch>
			<eupload v-else-if="item.type == 6" :config="item.opt" v-model="model[item.field]"></eupload>
			<edate v-else-if="item.type == 7" :config="item.opt" v-model="model[item.field]" @set="change(item)">
			</edate>
			<etime v-else-if="item.type == 701" :config="item.opt" v-model="model[item.field]"></etime>
			<edateRange v-else-if="item.type == 702" :config="item.opt" v-model="model[item.field]" @set="change(item)">
			</edateRange>
			<!-- <eeditor v-else-if="item.type == 8" :config="item.opt" v-model="model[item.field]"></eeditor> -->
			<ecascader v-else-if="item.type == 9" :config="item.opt" v-model="model[item.field]" @set="change(item)">
			</ecascader>
			<span v-else-if="item.type==10" v-text="model[item.field]"></span>
			<el-input v-else-if="item.type == 101" :placeholder="item.opt.placeholder" clearable
				v-model.number="model[item.field]" @clear="clear" />
			<el-input v-else-if="item.type == 102" :placeholder="item.opt.placeholder" clearable
				v-model="model[item.field]" type="textarea" :rows="item.opt.rows" :maxlength="item.max||500"
				show-word-limit @clear="clear" />
			<el-input :type="item.opt.type" :placeholder="item.opt.placeholder" clearable
				:maxlength="item.opt.maxlength" v-model.trim="model[item.field]" :disabled="item.opt.disabled"
				@keyup.enter.native="keyup($event,index)" @clear="clear" v-else>
				<template #append v-if="item.opt.append">
					<span v-text="item.opt.append"></span>
				</template>
			</el-input>
		</el-form-item>
		<el-form-item v-if="btn.length">
			<ebtn @action="btnClick($event,item)" :label="item.label" :icon="item.icon" :type="item.type" :size="size"
				v-for="item in btn" :key="item.action">
			</ebtn>
		</el-form-item>
	</el-form>
</template>

<script>
	import { ref, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'eformBase',
		props: {
			labelWidth: {
				type: String,
				default: '115px'
			},
			labelPosition: {
				type: String,
				default: 'top'
			},
			labelSuffix: {
				type: String,
				default: '：'
			},
			inline: {
				type: Boolean,
				default: true
			},
			size: {
				type: String,
				default: ''
			},
			class: {
				type: String,
				default: ''
			},
			btns: { type: Array },
			rule: {//是否启用校验
				type: Boolean,
				default: true
			},
			// mode: { type: Number, default: 1 },//1表单 2搜索
			seacrhMode: {
				type: Boolean,
				default: false
			},
			/***
				type 1下拉 2单选 3复选 4开关 5上传 6日期 7时间 8富文本 其他文本
				label标题
				opt 扩展
				required必填
				min最小长度
				max最大长度
				reg正则
				msg:{
					required必填提示
					min最小长度提示
					max最大长度提示
					reg正则提示
				}
				***/
			fields: {
				type: Object
			},
			values: {
				type: Object
			}
		},
		emits: ['submit'],
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let fields = reactive([])
			let rules = reactive({})
			let model = reactive({})
			let btn = reactive([])
			let formRef = ref(null)

			const get_default_opt = (field) => {
				let type = field.type
				let opt = proxy.$copy(field.opt) || {};
				if (type == 6) {//默认日期类型
					opt.type = opt.type || 'date'
					opt.format = opt.format || 'yyyy-MM-dd'
				} else if (!type) {
					opt.type = opt.type || "text"
					// opt.placeholder = opt.placeholder;
					if (opt.maxlength) { }
					else if (field.reg == 'phone') opt.maxlength = 11
					else if (field.reg == 'email') opt.maxlength = 30
					else if (field.max) opt.maxlength = field.max
				}

				//联动
				let affect = {}
				// if (opt && proxy.$isObj(opt.params)) {
				// 	let str = JSON.stringify(opt.params)
				// 	var reg = /\{([a-zA-Z]+)\}/ig;
				// 	let res = []

				// 	var r = "";
				// 	while (r = reg.exec(str)) {
				// 		res.push(r[1])
				// 	}
				// 	if (res.length > 0) {
				// 		affect.flag = true; //等待联动
				// 		affect.str = str//参数字符串
				// 		affect.fields = res//受影响字段集合
				// 	}
				// }
				// affect = Object.assign({}, affect, field.affect)
				return { opt, affect }
			}
			const get_default_rule = (key, field) => {
				let t = {
					label: field.label || '',
					input: field.input,
					required: field.required,
					requiredMsg: field.requiredMsg || field.opt?.placeholder,
					min: field.min, max: field.max, reg: field.reg,
					minVal: field.minVal, maxVal: field.maxVal,
				}
				if (props.seacrhMode) {
					rules[key] = [];
				} else {
					rules[key] = [{ validator: formValidator, trigger: 'change' }]
					if (field.required) rules[key].push({ required: true, message: (field.label || '') + '不可为空', trigger: 'blur' })
				}
				return t
			}
			const get_default_val = (filed, item) => {
				let val = item.value;
				if (!val && props.values) {
					val = props.values[filed];
				}
				return val;
			}
			const formValidator = (rule, value, callback) => {
				let field = fields.filter(c => { return c.field == rule.field })[0]
				if (!field) return callback();
				let r = field.rule

				if (proxy.$isEmpty(value)) {
					if (r.required) {
						let required = proxy.$isFunc(r.required) ? r.required(rule.field, proxy.$copy(this.model)) : r.required

						let requiredMsg
						if (proxy.$isStr(required)) {
							required = true
							requiredMsg = required
						}
						requiredMsg = requiredMsg || r.requiredMsg || r.label + '不可为空'

						if (required) {
							return callback(new Error(requiredMsg));
						}
					}
					return callback();
				}

				if (r.minVal || r.maxVal) {
					var intV = parseFloat(value)
					if (r.minVal && r.maxVal) {
						if (r.minVal > intV || r.maxVal < intV) {
							return callback(new Error(`应为${r.minVal}-${r.maxVal}之间`));
						}
					} else if (r.minVal && r.minVal > intV) {
						return callback(new Error(`不能小于${r.minVal}`));
					} else if (r.maxVal && r.maxVal < intV) {
						return callback(new Error(`不能大于${r.maxVal}`));
					}
				}
				if (r.min || r.max) {
					let str = value + ''
					if (r.min > str.length || r.max < str.length) {
						return callback(new Error(r.min && r.max ? `应为${r.min}-${r.max}个字符` : `最多支持${r.max}个字符`));
					}
				}

				if (r.reg) {
					let reg;
					let regMsg;
					if (proxy.$isObj(r.reg)) {
						reg = r.reg.reg;
						regMsg = r.reg.msg;
					} else {
						reg = r.reg
					}

					if (proxy.$isStr(reg)) {
						var _reg = RegExp.const[reg]
						if (_reg) {
							reg = _reg.reg
							regMsg = _reg.msg
						}
					}

					if (reg) {
						reg = proxy.$isStr(reg) ? new RegExp(reg) : reg;
						let message = regMsg || (field.label + '格式不正确');
						if (!reg.test(value)) {
							return callback(new Error(message));
						}
					}
				}
				callback();
			}

			for (let key in props.fields) {
				if (props.fields.hasOwnProperty(key)) {
					var f = props.fields[key];
					let field = f.field || key;
					fields.push({
						type: f.type,
						field: field,
						label: f.label || '',
						sort: f.sort || 99,
						width: f.width,
						rule: get_default_rule(field, f),
						// component: components[f.type],
						...get_default_opt(f)
					});
					model[field] = get_default_val(field, f)
					// this.$set(this.model, field, this.get_default_val(field, f));
				}
			}
			fields = fields.sort((a, b) => a.sort - b.sort);

			let defaultBtn = {
				ok: { label: '确定', action: 'ok', type: 'primary' },
				cancel: { label: '取消', action: 'cancel', type: '' }
			}
			if (!props.btns) {
				btn = [defaultBtn.cancel, defaultBtn.ok]
			} else {
				btn = props.btns.map(t => { return Object.assign({}, defaultBtn[t.action], t) });
			}

			const btnClick = (cb, item) => {
				if (item.action == 'ok') {
					submit(cb)
				} else if (item.action == 'cancel') {
					cancel(cb)
				} else {
					emit('click', item.action, cb);
				}
			}
			const change = ({ field }) => {
				//更新联动组件
				var flag = false
				// for (let i = 0; i < fields.length; i++) {
				// 	let p = fields[i];
				// 	if (!p.affect.fields || !p.affect.fields.includes(field)) continue
				// 	p.affect.flag = true
				// 	model[prop.field] = ''
				// 	fields[i] = prop
				// 	//this.$set(this.model, prop.field, '')
				// 	//this.$set(this.props, i, prop)//先禁用

				// 	//this.$nextTick(() => {
				// 	p.affect.flag = p.affect.check ? p.affect.check(prop, model) === false : p.affect.fields.some(c => { return !model[c] })
				// 	p.params = JSON.parse(p.affect.str.formatModel(this.model))
				// 	// this.$set(this.props, i, prop)
				// 	fields[i] = prop
				// 	flag = true
				// 	//})
				// }
				if (!flag && proxy.seacrhMode) {
					submit(() => { })
				}
			}
			const keyup = (event, index) => {
				if (event.key == 'Enter') {
					if (props.seacrhMode) {
						submit(() => { })
					} else if (index == fields.length - 1) {
						submit(() => { })
						event.target.blur();
					}
				} else {//自定义校验
					let cur = fields[index]
					if (cur.rule && cur.rule.input) {
						event.target.value = event.target.value.replace(cur.rule.input, '')
					}
				}
			}
			const clear = () => {
				if (props.seacrhMode) {
					submit(() => { })
				}
			}
			const validate = (func) => {
				try {
					formRef.value && formRef.value.validate(func);
				} catch (error) {
					console.error(error);
				}
			}
			const submit = (cb) => {
				validate(valid => {
					if (valid) {
						var q = proxy.$copy(model)
						emit('submit', q, cb);
					} else cb();
				});
			}
			const cancel = (cb, defaultParams) => {
				defaultParams = defaultParams || {}
				for (const key in model) {
					if (Object.hasOwnProperty.call(model, key)) {
						model[key] = defaultParams[key] || ''
					}
				}
				emit('submit', {}, cb);
			}

			return { fields, rules, model, btn, btnClick, keyup, change, validate, cancel, clear, formRef };
		}
	};
</script>

<style scoped>
	.el-form-item {
		margin-bottom: 18px;
	}
</style>