export default {
	install(app) {
		app.config.globalProperties.$getToken = function () {
			let token = this.$storage.getToken()
			//是否过期

			return token
		}

		app.config.globalProperties.$hasAuth = function (code) {
			let user = this.$storage.get('user') || {}
			if (user.isAdmin) return true

			var path = location.pathname
			var p = window.$menus.find(c => c.path == path) || {}
			let power = p.meta?.power
			if (power && power.indexOf(code) >= 0) {
				return true
			}
		}
	}
}
