<template>
	<div>
		<el-input v-model="keyword" :placeholder="config.placeholder" @input="reload" clearable
			@keyup.enter.native="loadData" @clear="loadData">
			<template #append>
				<el-button @click="loadData">
					<i class="iconfont icon-search1"></i>
				</el-button>
			</template>
		</el-input>
		<div class="nodata" v-text="keyword?'未检索到相关记录':'暂无数据'" v-if="options.length==0"></div>
		<el-table :data="options" style="margin-top: 15px;margin-bottom: 35px;" v-if="options.length"
			v-loading="loading">
			<template v-for="(item, index) in config.cols" :key="index">
				<el-table-column min-width="120" :label="item.label" :prop="item.field" show-overflow-tooltip>
				</el-table-column>
			</template>
			<el-table-column label="操作" :resizable="false" header-align="center" width="70">
				<template #default="{ row, $index }">
					<el-button link @click="selection(row)">选择
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- <el-pagination layout="prev,pager,next" :page-size="page.size" :total="page.total" @current-change="changePage"
			pager-count="5" v-if="page.total">
		</el-pagination> -->
	</div>
</template>

<script>
	import { ref, watch, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'esingleTable',//单选表格
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// data: [], 数据源
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
					// placeholder:'' 提示语
					// cols:[] 列
					// remember
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let config = props.config || {}
			let options = ref([])
			let loading = ref(false);
			let keyword = ref('')
			let remember = !!config.remember

			const loadData = () => {
				loading.value = true
				let q = proxy.$isFunc(config.params) ? config.params() : Object.assign({}, config.params, { keyword: keyword.value })
				proxy.$post(config.url, q, ({ data }) => {
					formatData(data);
					loading.value = false
				});
			}
			const formatData = (data) => {
				data = data.items || data || []
				if (proxy.$isEmpty(data)) {
					options.value = [];
					return;
				}
				if (proxy.$isFunc(config.filter)) {
					data = config.filter(data);
				}
				options.value = data;
			}
			const selection = (row) => {
				if (remember) {
					proxy.$storage.set(config.remember, row[config.cols[0].field])
				}
				emit('selection', { row });
			}
			if (remember) {
				keyword.value = proxy.$storage.getString(config.remember)
			}
			config.url ? loadData() : formatData(config.data)

			return { options, loading, keyword, loadData, selection }
		},
	};
</script>