<template>
    <emodal :title="config.title" @submit="submit" v-model="show" width="40%">
        <el-tree :data="tree" draggable :expand-on-click-node="false" node-key="id" default-expand-all ref="treeRef"
            emptyText="点击新增按钮，添加一条吧">
            <template #default="{ node, data }">
                <span>
                    <span>{{ node.label }}</span>
                    <span class="el-tree-icons">
                        <a @click="append(data)"> 新增 </a>
                        <a @click="edit(data)"> 修改 </a>
                        <a @click="remove(node, data)" v-if="data.canDel"> 删除 </a>
                    </span>
                </span>
            </template>
        </el-tree>
        <el-button @click="append()" style="margin-left: 10px;margin-top: 10px;width: 100px;">新增</el-button>
    </emodal>
</template>

<script>
    import { ElMessage, ElMessageBox } from 'element-plus'
    import { ref, reactive, getCurrentInstance } from "vue";
    export default {
        name: 'emodalTree',//弹层树操作
        props: {
            value: { type: Boolean },
            config: { type: Object }
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            let show = ref(props.value)
            const tree = ref([])
            let loading = ref(false);
            let treeRef = ref(false);

            let config = props.config || {};
            var title = config.title || '分组';
            (function () {
                loading.value = true
                proxy.$post(config.url, config.params, ({ data }) => {
                    loading.value = false
                    tree.value = (data || []).formatAndToTree()
                });
            })()
            let ec = Object.assign({ name: title, placeholder: '请输入', check: null }, config.edit)

            const append = (data) => {
                showModal('', '', ({ value }) => {
                    const n = { id: '_' + new Date().getTime(), created: true, label: value, canDel: true, children: [] }
                    if (data) {
                        if (!data.children) {
                            data.children = []
                        }
                        n.pid = data.id
                        data.children.push(n)
                    } else {
                        tree.value.push(n)
                    }
                    // tree.value = [...tree.value]
                })
            }
            const edit = (data) => {
                showModal(data.id, data.label, ({ value }) => {
                    data.label = value
                    // tree.value = [...tree.value]
                })
            }
            const remove = (node, data) => {
                const parent = node.parent
                const children = parent.data.children || parent.data
                const index = children.findIndex((d) => d.id === data.id)
                children.splice(index, 1)
                //tree.value = [...tree.value]
            }
            const showModal = (id, val, func) => {
                var title = val ? '新增' : '修改'
                var datas = getData()
                ElMessageBox.prompt('', title + ec.name, {
                    draggable: true,
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    inputValue: val,
                    inputPlaceholder: ec.placeholder,
                    inputValidator(val) {
                        if (ec.check) return ec.check(val)
                        if (val == '') return '不可为空'
                        else if (val.length > 30) return '最多支持30位字符'
                        // else if (datas.some(c => (c.id != id || !c.id) && c.name == val)) return `名称已存在`//去重
                    }
                }).then(func).catch(() => { })
            }
            const getData = () => {
                var res = []
                function get(c, i, pid) {
                    res.push({ id: c.id, pid: pid, name: c.label, created: c.created, sort: i + 1 })
                    if (c.children) {
                        c.children.forEach((_c, _i) => { get(_c, _i, c.id) });
                    }
                }
                tree.value.forEach((_c, _i) => { get(_c, _i) });
                return res
            }
            const submit = (cb) => {
                var func = (res) => {
                    if (res && res.success) {
                        emit('finish');
                        emit('update:modelValue', false);
                        proxy.$tips('保存成功');
                        cb()
                    } else {
                        proxy.$err(res.msg, cb);
                    }
                }

                var data = getData()
                if (proxy.$isStr(config.submit)) {
                    proxy.$post(config.submit, { ...config.params, items: JSON.stringify(data) }, func);
                } else {
                    config.submit(data, func)
                }
            }
            return { show, tree, append, edit, remove, submit, treeRef };
        }
    };
</script>
<style scoped>
    .el-tree-icons {
        color: #409eff;
        font-size: 12px;
    }

    .el-tree-icons a {
        margin-left: 5px;
    }
</style>