import extend from './base/extend.js'
import tips from './base/tips.js'
import storage from './base/storage.js'
import auth from './base/auth.js'
import request from './base/request.js'
import filter from './base/filter.js'
import echarts from './base/echarts';

// import directive from './base/directive.js'
// import excel from './base/excel.js'

import etable from '../components/table/basics'
import etreeTable from '../components/table/tree'

import eform from '../components/form/index'
import eformBase from '../components/form/base'
import eselect from '../components/form/select'
import eselectTable from '../components/form/selectTable'
import eselectTree from '../components/form/selectTree'
import eradio from '../components/form/radio'
import echeckbox from '../components/form/checkbox'
import eswitch from '../components/form/switch'
import edate from '../components/form/date'
import etime from '../components/form/time'
import etime2 from '../components/form/time2'
import edateRange from '../components/form/dateRange'
import eeditor from '../components/form/editor'
import etext from '../components/form/text'
import ecascader from '../components/form/cascader'
import esingleTable from '../components/form/singleTable'

import etag from '../components/other/tag'
import eimg from '../components/other/img'
import eimgView from '../components/other/imgView'
import ebtn from '../components/other/button'
import elink from '../components/other/link'

import emodal from '../components/modal/base'
import emodalForm from '../components/modal/form'
import emodalTree from '../components/modal/tree'
import emodalDetails from '../components/modal/details'
import emodelDrawer from '../components/modal/drawer'
import emodalImport from '../components/upload/import'

import euploadImg from '../components/upload/img'
import euploadFile from '../components/upload/file'
// import ptable from '../components/page/table'

import { getRoute } from './base/util'
import router from './router'

export default {
    install(app, opt) {
        app.use(extend)
        app.use(tips)
        app.config.globalProperties.getPath = opt.getPath
        app.config.globalProperties.replacePath = opt.replacePath

        app.config.globalProperties.$config = opt
        app.config.globalProperties.$storage = storage
        app.config.globalProperties.$echarts = echarts
        app.use(auth)
        app.use(request, opt)
        app.use(filter)
        // app.use(directive)
        // app.config.globalProperties.$excel = excel

        const components = [eform, eformBase, ebtn, etext, elink, eimg, eimgView, etag,
            emodal, emodalForm, emodalImport,
            eselectTable, emodalTree, emodalDetails, emodelDrawer, etable,
            eswitch, echeckbox, eselect, eradio, euploadImg, euploadFile, edate, etime,etime2, edateRange, eeditor, ecascader, esingleTable, eselectTree, etreeTable]
        components.forEach(component => {
            app.component(component.name, component);
        });

        //跳转页面
        app.config.globalProperties.$to = function (route) {
            route = getRoute(route)
            router.push(route)
        }
        app.config.globalProperties.$to2 = function (route) {
            route = getRoute(route)
            this.$router.removeRoute(route.name)
            this.$router.addRoute('home', route)
            router.push(route)
        }
        app.config.globalProperties.$close = function () {
            var name = this.$store.state.tabActive
            let index = this.$store.state.tabs.findIndex(c => { return c.name == name })
            if (index >= 0) {
                this.$store.commit('removeTab', name)
                var t = this.$store.state.tabs[index - 1]
                this.$to({ path: t.path, meta: { title: t.title } })
            }
        }
    }
}