<template>
	<el-switch v-model="val" @change="setValue"></el-switch>
</template>

<script>
	import { ref, watch, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'eswitch',
		props: {
			modelValue: {},
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const val = ref(false)

			const formatValue = () => {
				var v = props.modelValue
				val.value = v == 1 || v == true
				return val.value
			}
			const setValue = (value) => {
				emit('update:modelValue', value);
				emit('set', { value, cancel });
			}
			const cancel = (value) => {
				val.value = !val.value
				emit('update:modelValue', val.value);
			}

			watch(props, formatValue);
			formatValue();
			return { val, cancel, setValue }
		}
	};
</script>