<template>
	<el-select ref="sel" v-model="val" :multiple="multiple" :placeholder="config.placeholder" :loading="loading"
		:filter-method="loadData" popper-append-to-body collapse-tags filterable clearable @clear="clear"
		@remove-tag="removeTag" @focus="selectFocus">
		<template #empty>
			<el-table :data="options" @selection-change="selection" @row-click="rowClick" ref="tableRef">
				<el-table-column v-if="multiple" type="selection" width="50px"></el-table-column>
				<template v-for="(item, index) in cols" :key="index">
					<el-table-column min-width="120" sortable :label="item.label" :prop="item.field"
						show-overflow-tooltip>
					</el-table-column>
				</template>
			</el-table>
		</template>
	</el-select>
</template>

<script>
	import { ref, watch, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'eselectTable',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// data: [], 数据源
					// labelKey: '',显示字段名 默认label
					// valueKey: '',实际值字段名 默认value
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
					// placeholder:'' 提示语
					// cols:[] 列
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let config = props.config || {}
			let options = ref([])
			let cols = ref(config.cols)
			let multiple = ref(!!config.multiple)
			let loading = ref(false);
			let val = ref('');
			let tableRef = ref(null)
			let selected = []
			let oldKeyword

			watch(props, (newValue, oldValue) => {
				if (!newValue.modelValue) {//清空
					clear()
				}
			});

			const loadData = (query) => {
				if (query == undefined) return
				query = query || ''
				if (oldKeyword == query) return
				clear()
				oldKeyword = query
				if (!config.url) return;
				loading.value = true
				let q = proxy.$isFunc(config.params) ? config.params() : Object.assign({}, config.params, { [config.searchKey || 'keyword']: query })
				proxy.$post(config.url, q, ({ data }) => {
					formatData(data);
					loading.value = false
				});
			}
			const formatData = (data) => {
				data = data.items || data || []
				if (proxy.$isEmpty(data)) {
					options.value = [];
					return;
				}
				if (proxy.$isFunc(config.filter)) {
					data = config.filter(data);
				}
				data.forEach(item => {
					item.label = (item[config.labelKey || 'name'] || item.label)
					item.value = (item[config.valueKey || 'id'] || item.value) + ''
				});
				options.value = data;

				if (multiple.value) {
					if (proxy.$isEmpty(props.modelValue)) {
						val.value = [];
					} else if (proxy.$isArr(props.modelValue)) {
						val.value = props.modelValue.map(c => { return c + '' });
					} else {
						val.value = (props.modelValue + '').split(',');
					}
				} else {
					val.value = (props.modelValue || '') + '';
				}
			}
			const selection = (data) => {
				selected = data
				setValue()
			}
			const rowClick = (row, cell, el) => {
				if (!multiple.value) {
					tableRef.value.clearSelection()
				}
				tableRef.value.toggleRowSelection(row, true)
			}
			const clear = () => {
				selected = []
				tableRef.value && tableRef.value.clearSelection()
			}
			const removeTag = (tag) => {
				selected.map(c => {
					if (c.label === tag) tableRef.value.toggleRowSelection(c)
				})
			}
			const selectFocus = (tag) => {
				if (selected && options.value.length > 0) {//重置搜索条件
					clear()
				}
			}

			const setValue = () => {
				let value = selected.map(c => c.value)
				let label = selected.map(c => c.label)
				//console.log(value);
				val.value = label
				emit('update:modelValue', value);
				emit('change', { value: proxy.$copy(selected) });
			}
			config.url ? loadData() : formatData(config.data)

			return { options, cols, multiple, loading, val, loadData, selection, rowClick, setValue, clear, removeTag, selectFocus, tableRef }
		},
	};
</script>