<template>
	<emodal :title="title" :btns="[]" v-model="show" width="600px">
		<el-upload drag action="" :auto-upload="false" :multiple="false" :accept="s.accept" :on-change="upload"
			:show-file-list="false" style="text-align: center;margin: 10px auto;width: 80%;">
			<!-- <i class="el-icon-upload"></i> -->
			<img src="/static/img/icon/upload.png" />
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip">
				<div v-html="tips"></div>
				<div v-show="pro" style="margin-top: 5px;color: #ee400b;" v-text="pro"></div>
			</div>
		</el-upload>
		<div style="text-align: center;margin: 25px 0;">
			<el-link type="primary" style="color:red;margin-right: 15px;" @click.stop="downErrorFile"
				v-if="err_path">点击下载错误文件</el-link>
			<el-link type="primary" @click.stop="downTemplate">点击下载模板</el-link>
		</div>
	</emodal>
</template>

<script>
	import { ref, getCurrentInstance, watch } from "vue";
	export default {
		name: 'emodalImport',//导入
		props: {
			value: { type: Boolean },
			template: { type: String },//模版请求url
			url: { type: String },//提交请求url
			params: { type: Object, default: {} },//提交请求参数
			config: {
				type: Object,
				default: {
					//type:文件类型 accept: '',支持的文件类型 size:'500kb',支持的文件大小
					//title
					//tips
					//url
				}
			}
		},
		emits: ['success', 'down'],
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const config = props.config || {}
			let _cfg = proxy.$config.upload
			const pro = ref('')
			let s = config.type || _cfg.import
			const title = config.title || '导入'
			const tips = (config.tips || '请上传单个{accept}格式文件，且文件大小不超过{size}').formatModel(s)
			const err_path = ref('')

			const downTemplate = () => {
				if (proxy.template) {
					proxy.$post(proxy.template, { t: new Date().getTime() }, ({ data }) => {
						data = data || {}
						proxy.$downFile(data.path)
					})
				} else {
					emit('down');
				}
			}
			const downErrorFile = () => {
				if (err_path.value) {
					proxy.$downFile(err_path.value)
				}
			}

			const upload = (file) => {
				err_path.value = ''
				proxy.$uploadChunk(s, file.raw, ({ success, msg, data, progress }) => {
					if (progress) {
						pro.value = `文件上传中${progress}%...`
					} else if (success) {
						pro.value = '文件导入中'
						importSubmit(data.path)
					} else {
						pro.value = ''
						proxy.$err(msg);
					}
				})
			}

			const importSubmit = (path) => {
				var q = Object.assign({}, props.params, { path })
				proxy.$post(props.url, q, res => {
					pro.value = ''
					var { success, msg, data, code } = res;
					if (data && data.path) {
						if (code == 5) {
							proxy.$tips('导出成功')
							emit('success');
						} else {
							proxy.$err('导入失败，请下载错误文件查看详情')
							err_path.value = data.path
							proxy.$downFile(data.path)
						}
					} else if (success) {
						proxy.$tips(msg || '导入成功')
						emit('success');
					} else {
						proxy.$err(msg || '导入失败，请稍后重试')
					}
				});
			}

			// const close = () => {
			// 	emit('update:modelValue', false);
			// 	// emit('close', false);
			// }
			let show = ref(props.value)
			// watch(props, (newValue, oldValue) => {
			// 	show.value = newValue.modelValue
			// });

			return { show, tips, title, pro, s, err_path, downTemplate, upload, downErrorFile };
		}
	};
</script>