export default {
    install(app) {
        app.config.globalProperties.$month = function (str) {
            if (!str) return str
            str += ''
            return str.substr(0, 4) + '-' + str.substr(4, 2);
        }
        app.config.globalProperties.$price = function (num) {
            if (!num) return num
            num = parseInt(num) / 100.00

            // 判断是否为数字
            if (isNaN(num)) {
                return '';
            }

            // 转为字符串并去除两端空格
            num = String(num).trim();

            // 判断是否为负数
            let isNegative = num[0] === '-';
            if (isNegative) {
                num = num.substr(1);
            }

            // 分离整数部分和小数部分
            let parts = num.split('.');
            let integerPart = parts[0];
            let decimalPart = parts[1] || '';

            // 插入千分符
            integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,');

            // 重新拼接整数部分和小数部分
            let result = integerPart + (decimalPart ? '.' + decimalPart : '');

            // 如果是负数，则添加负号
            if (isNegative) {
                result = '-' + result;
            }

            return result;
        }
    }
}