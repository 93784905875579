<template>
	<el-date-picker v-model="val" clearable type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"
		:value-format="format" @change="setValue" :shortcuts="shortcuts" />
</template>

<script>
	import { ref, watch, getCurrentInstance } from "vue";
	export default {
		name: 'edateRange',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					//value
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let config = props.config || {};
			let format = ref('YYYY-MM-DD HH:mm:ss')
			var defaultValue = []
			let val = ref([])

			watch(props, (newValue, oldValue) => {
				if (!newValue.modelValue) {//清空
					val.value = defaultValue;
				}
			});
			const setValue = (value) => {
				proxy.$emit('update:modelValue', value);
				proxy.$emit('set', { value });
			}

			if (props.modelValue && props.modelValue.length == 2) {
				defaultValue = [props.modelValue[0].s().format(format.value), props.modelValue[1].e().format(format.value)]
				val.value = defaultValue
				setValue(defaultValue)
			}

			let shortcuts = ref([{
				text: '今日',
				value: () => {
					const end = new Date()
					return [end.s(), end.e()]
				}
			}, {
				text: '昨日',
				value: () => {
					const end = new Date();
					end.setDate(end.getDate() - 1)
					return [end.s(), end.e()]
				}
			}, {
				text: '本周',
				value: () => {
					const end = new Date();
					let week = end.getDay()
					const start = new Date();
					start.setDate(end.getDate() - (week ? week - 1 : 6))
					return [start.s(), end.e()]
				}
			}, {
				text: '本月',
				value: () => {
					const end = new Date();
					const start = new Date(end.getFullYear(), end.getMonth(), 1)
					return [start.s(), end.e()]
				}
			}, {
				text: '本季度',
				value: () => {
					const end = new Date();
					const start = new Date(end.getFullYear(), parseInt(end.getMonth() / 3) * 3, 1)
					return [start.s(), end.e()]
				}
			}, {
				text: '本年度',
				value: () => {
					const end = new Date();
					const start = new Date(end.getFullYear(), 0, 1)
					return [start.s(), end.e()]
				}
			}, {
				text: '最近三个月',
				value: () => {
					const start = new Date();
					const end = new Date();
					start.setMonth(end.getMonth() - 3)
					return [start.s(), end.e()]
				}
			}])
			return { format, val, shortcuts, setValue }
		}
	};
</script>