<template>
    <div class="tree-table">
        <div class="tree-table-l" v-show="treeFlag">
            <el-tree :data="treeData" node-key="id" @node-click="changeGroup" default-expand-all
                :expand-on-click-node="false" highlight-current ref="treeRef">
                <template #default="{ node, data }">
                    <span>{{ node.label }}</span>
                </template>
            </el-tree>
            <div class="el-bottom-btn" @click="showGroup" v-if="groupAuth" v-text="title"></div>
            <div class="iconfont icon-arrow-double-left slide-arrow" @click="showTree" v-if="treeFlag"></div>
        </div>
        <div class="tree-table-r">
            <slot></slot>
        </div>
        <div class="iconfont icon-arrow-double-right slide-arrow" @click="showTree" v-if="!treeFlag"></div>

        <emodalTree :config="groupCfg" v-model="groupCfg.show" @finish="reload">
        </emodalTree>
    </div>
</template>

<script>
    import { ref, getCurrentInstance } from "vue";
    export default {
        name: 'etreeTable',
        props: { cate: {} },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const treeRef = ref(null)
            const cate = props.cate || {}
            const title = ref(cate.title)

            const groupAuth = true//ref(proxy.$hasAuth(title))
            const groupCfg = ref({
                show: false,
                title: title.value,
                url: cate.url,
                canDel: true,
                submit: cate.submit
            })
            const treeData = ref([])
            const defaultTitle = '全部' + title.value.substr(0, title.length - 2)
            const loadGroup = () => {
                proxy.$post(groupCfg.value.url, {}, ({ data }) => {
                    var total = 0
                    data.forEach(item => {
                        total += item.refCount || 0
                    });
                    treeData.value = [{ id: '', label: defaultTitle, refCount: total, children: (data || []).formatAndToTree() }]
                })
            }
            loadGroup()
            const showGroup = () => {
                groupCfg.value.show = true
            }
            const changeGroup = () => {
                emit('reload', (treeRef.value?.getCurrentNode() || {}).id)
            }
            const reload = () => {
                loadGroup()
                changeGroup()
            }

            const treeFlag = ref(!!localStorage.getItem('tree_flag_' + proxy.$route.name))
            const showTree = () => {
                treeFlag.value = !treeFlag.value
                localStorage.setItem('tree_flag_' + proxy.$route.name, treeFlag.value)
            }

            return {
                title, treeData, treeFlag, showTree, reload, treeRef,
                groupAuth, groupCfg, showGroup, changeGroup
            };
        }
    };
</script>

<style scoped>
</style>