<template>
	<div class="kindeditor">
		<textarea id="kindeditor" name="content" v-model="val"></textarea>
		<input class="js-custom-upload-img" type="file" style="display:none;" ref="imgRef" :accept="imgAccept"
			@change="imgChange" />

		<emodal title="上传视频" :btns="[]" v-model="videoShow" width="465px">
			<div style="text-align: center;margin-top: 30px;margin-bottom: 50px;">
				<euploadFile :config="{type:'video'}" @upload="videoChange"></euploadFile>
			</div>
		</emodal>
	</div>
</template>

<script>
	import '@static/js/kindeditor/kindeditor-all.js'
	// import '@assets/js/kindeditor/lang/zh-CN.js'
	import '@static/js/kindeditor/themes/default/default.css'
	import { ref, watch, onMounted, onBeforeUnmount, getCurrentInstance } from "vue";
	export default {
		name: 'eeditor',
		props: {
			modelValue: String,
			config: {
				type: Object,
				default: {
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const imgRef = ref(null)
			let _cfg = proxy.$config.upload
			const imgAccept = _cfg.img.accept
			const imgSize = _cfg.img.size
			// const videoCfg = _cfg.video
			const videoShow = ref(false)
			let val = ref('')
			let editor

			const setValue = (value) => {
				// emit('update:modelValue', value);
				emit('set', { value: value });
			}
			const initPlugin = () => {
				window.KindEditor.plugin('customImg', function (K) {
					//editor = this
					this.clickToolbar('customImg', function () {
						imgRef.value.click()
					});
				});
				window.KindEditor.plugin('customVideo', function (K) {
					this.clickToolbar('customVideo', function () {
						videoShow.value = true
					});
				});
				KindEditor.lang({
					customImg: '插入图片',
					customVideo: '插入视频'
				});
			}
			initPlugin()

			const init = () => {
				// editor && editor.remove && editor.remove()
				window.KindEditor.remove('#kindeditor')
				editor = window.KindEditor.create('#kindeditor', {
					// width: '98%',
					width: '500px',
					minHeight: 230,
					resizeType: 1,
					allowImageUpload: false,
					allowFileManager: false,
					emoticonsPath: '/static/img/emoticons/',
					// themesPath:'',
					// pluginsPath:'',
					basePath: '/static/js/kindeditor/',
					afterChange() {
						setValue(this.html())
					},
					items: ['source', 'fontname', 'fontsize', '|', 'forecolor', 'hilitecolor', 'bold', 'italic', 'underline',
						'removeformat', '|', 'justifyleft', 'justifycenter', 'justifyright', 'insertorderedlist',
						'insertunorderedlist', '|', 'emoticons', 'link', 'customImg', 'customVideo', '|', 'fullscreen']
					//'link', 'customVideo', 
				})
				window.KindEditor.html('#kindeditor', proxy.modelValue || '')
			}
			// onActivated(init)
			watch(props, init);
			onMounted(init)

			const imgChange = () => {
				let f = document.querySelector('.js-custom-upload-img').files[0]
				if (!f) return;

				proxy.$upload(null, f, ({ success, msg, data }) => {
					if (success) {
						//insertimage(url , title , width , height , border , align)
						editor.exec('insertimage', proxy.getPath(data.path), f.name);
					} else {
						proxy.$err(msg);
					}
				})
			}

			const videoChange = ({ path }) => {
				videoShow.value = false
				editor.exec('inserthtml', `<video src="${path}" width="100%" height="200" controls="controls">您的浏览器不支持视频播放。</video>`);
			}
			const getValue = () => {
				return editor.html()
			}

			return { val, imgRef, imgAccept, imgSize, videoShow, imgChange, videoChange, getValue }
		}
	};
</script>

<style lang="css" scoped>
	>>>.ke-icon-customImg {
		background-position: 0px -496px;
		width: 16px;
		height: 16px;
	}

	>>>.ke-icon-customVideo {
		background-position: 0px -528px;
		width: 16px;
		height: 16px;
	}
</style>