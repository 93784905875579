import axios from 'axios'
import storage from "./storage"
import router from "../router"
import Qs from 'qs'
import BMF from 'browser-md5-file'

export default {
	install(app, options) {
		const http = axios.create({
			// baseURL: options.domain,
			timeout: 30000,
		})

		http.interceptors.request.use(
			opt => {
				if (!opt.headers['Content-Type']) {
					if (opt.data) {
						opt.data.token = storage.getToken()
						opt.data.platform = 1
						opt.data = Qs.stringify(opt.data)
					}
					opt.headers = {
						"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" //"application/json; charset=utf-8"//
					}
				}
				if (!opt.url.startsWith('http')) {
					opt.url = options.getPath(opt.url)
				}
				if (opt.url.indexOf('export')) {
					opt.timeout = 1000 * 60 * 3
				} else {
					opt.timeout = 30000
				}
				return opt
			},
			error => {
				return Promise.reject(error)
			}
		)

		http.interceptors.response.use(
			({ data }) => {
				if (data.code == 1) {//token失效
					storage.setToken('')
					$toLogin()
					//router.push('/login?url=' + encodeURIComponent(router.currentRoute.fullPath || ''))
					return Promise.reject({ msg: '登录失效，请重新登录' });
				} else if (data.code == 0 || data.code == 5 || data.code == 4) {
					return Promise.resolve({ success: true, data: data.data, code: data.code, msg: data.msg, res: data });
				} else {
					return Promise.reject({ msg: data.msg, code: data.code });
				}
			}, error => {
				// console.error(error);
				return Promise.reject({ msg: '系统异常，请稍后重试' });
			}
		)
		app.config.globalProperties.$reqest = http
		// axios.defaults.baseURL = '/api'
		// axios.defaults.headers = { "Content-Type": "application/x-www-form-urlencoded" }
		app.config.globalProperties.$get = function (url, params, func) {
			func = func || function () { }
			params = params || {}
			return this.$reqest.get(url, { params }).then(res => {
				try {
					func(res)
				} catch (error) {
					console.error(error);
				}
			}).catch(res => { func({ msg: res.msg }) })
		}
		app.config.globalProperties.$post2 = function (url, params, func) {
			params = params || {}
			return this.$reqest.post(url, params).then(res => {
				try {
					func && func(res)
				} catch (error) {
					console.error(error);
				}
			}).catch(res => { func && func(res) })
		}
		app.config.globalProperties.$post = function (url, params, cb, runCount) {
			runCount = runCount || options.task.count || 5
			// var fullLoading = this.$loading({
			// 	fullscreen: true,
			// 	lock: true,
			// 	text: '排班中，请稍等...',
			// 	customClass: 'loadingFull'
			// })
			var fullLoading
			var func = function (t) {
				cb && cb(t)
				fullLoading && fullLoading.close()
			}
			return this.$post2(url, params, ({ success, msg, code, data }) => {
				if (success) {
					if (code == 4 && data && data.taskNo) {
						var num = 0
						fullLoading = this.$loading()
						var getRes = () => {
							this.$post2(options.task.url, { no: data.taskNo, type: data.type }, res => {
								num++
								if (res.success) {
									if (res.code == 4) {
										if (num >= runCount) {
											func && func({ msg: '操作超时，请确认设备是否在线' })
										} else {
											setTimeout(getRes, 2000);
										}
									} else {
										func && func({ success: true, msg: res.msg })
									}
								} else {
									func && func({ msg: res.msg })
								}
							})
						}
						setTimeout(getRes, 2000);
					} else if (code == 5) {
						this.$downFile(data.path)
						func && func({ success, msg, data, code })
					} else {
						func && func({ success, msg, data, code })
					}
				} else {
					func && func({ msg, msg, code, data })
				}
			});
		}
		app.config.globalProperties.$upload = function (opt, file, func) {
			if (!file || !file.size) {
				func({ msg: '请选择文件' })
				return
			}
			opt = opt || options.upload.img

			if (opt.size) {
				var max = opt.size.endsWith('Mb') ? parseInt(opt.size) * 1024 * 1024 : parseInt(opt.size) * 1024
				if (file.size > max) {
					func({ msg: `上传文件不能超过${opt.size}` })
					return
				}
			}

			if (opt.accept) {
				let ext = file.name.split('.')
				ext = '.' + ext[ext.length - 1]
				if (opt.accept.split(',').indexOf(ext) == -1) {
					func({ msg: `上传文件必须为${opt.accept}格式` })
					return
				}
			}

			if (file.size > 1 * 1024 * 1024) {
				return this.$uploadChunk(opt, file, res => {
					if (!res.progress) {
						func(res)
					}
				})
			}

			func = func || function () { }
			var form = new FormData();
			form.append("file", file);
			form.append("fileName", file.name || '');
			form.append('token', storage.getToken())
			return this.$reqest.post(opt.url, form, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
				try {
					func(res)
				} catch (error) {
					func({ msg: error.message })
				}
			}).catch(res => { func({ msg: res.msg }) })
		}
		app.config.globalProperties.$uploadChunk = function (opt, file, func) {
			if (!file || !file.size) {
				func({ msg: '请选择文件' })
				return
			}
			opt = opt || options.upload.img
			if (opt.size) {
				var max = opt.size.endsWith('Mb') ? parseInt(opt.size) * 1024 * 1024 : parseInt(opt.size) * 1024
				if (file.size > max) {
					func({ msg: `上传文件不能超过${opt.size}` })
					return
				}
			}

			if (opt.accept) {
				let ext = file.name.split('.')
				ext = '.' + ext[ext.length - 1]
				if (opt.accept.split(',').indexOf(ext) == -1) {
					func({ msg: `上传文件必须为${opt.accept}格式` })
					return
				}
			}

			//文件分片
			var index = 0
			var chunk_size = 300 * 1024//每片300kb
			var max = Math.ceil(file.size / chunk_size)

			//获取文件md5
			var md5 = ''
			const bmf = new BMF();
			bmf.md5(file, (err, res) => {
				if (!res) {
					func({ msg: '文件读取失败' })
					return
				}
				md5 = res
				//获取已上传文件索引
				var q = { md5, name: file.name, size: file.size }
				this.$post2(opt.getUrl, q, ({ success, msg, data }) => {
					if (success) {
						index = data.index || 0
						send()
					} else {
						func({ msg })
					}
				})
			});

			var getPro = () => {
				var pro_min = 100 / max * index
				var pro_max = 100 / max * (index + 1)
				pro_min += (pro_max - pro_min) / 2
				pro_min = Math.max(1, pro_min)
				pro_min = Math.min(100, pro_min)
				pro_max = Math.max(1, pro_max)
				pro_max = Math.min(100, pro_max)
				var pro = parseInt(Math.random() * (pro_max - pro_min + 1) + pro_min, 10)
				// console.log(index, pro_min, pro_max, pro);
				return pro
			}
			//上传
			var send = () => {
				var form = new FormData();
				let start = index * chunk_size
				let end = Math.min((index + 1) * chunk_size, file.size)
				form.append("file", file.slice(start, end));
				form.append("md5", md5);
				form.append("index", index);
				var last = index == max - 1
				form.append("last", last);
				form.append("szie", file.size);
				form.append("name", file.name || '');
				form.append('token', storage.getToken())
				var pro = last ? 99 : getPro()
				func({ progress: pro })
				this.$reqest.post(opt.saveUrl, form, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
					try {
						if (!last && res.success) {
							index++;
							send()
						} else {
							func(res)
						}
					} catch (error) {
						func({ msg: error.message })
					}
				}).catch(res => { func({ msg: res.msg }) })
			}
		}

		app.config.globalProperties.$downFile = function (url, params) {
			url = this.getPath(url)
			params = params || {}
			params.token = storage.getToken()
			params.platform = 1
			url += "?t=" + new Date().getTime()
			for (const key in params) {
				if (Object.hasOwnProperty.call(params, key)) {
					url += `&${key}=${params[key]}`
				}
			}
			// document.body.appendChild(`<iframe src="${encodeURI(url)}" style="width:0px; height:0px;display:none;"></iframe>`)

			var iframe = document.createElement("iframe");
			document.body.appendChild(iframe);
			iframe.style.display = "none"
			iframe.src = encodeURI(url);
		}

		app.config.globalProperties.$download = function (url, params, func) {
			func = func || function () { }
			params.token = storage.getToken()
			params.platform = 1
			url += "?"
			for (const key in params) {
				if (Object.hasOwnProperty.call(params, key)) {
					url += `${key}=${params[key]}&`
				}
			}
			return this.$reqest({
				method: 'get', responseType: 'blob', data: params, url,
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
			}).then(({ data }) => {
				// const fileUrl = window.URL.createObjectURL(new Blob([data]))
				// const link = document.createElement('a')
				// link.style.display = 'none'
				// link.href = fileUrl
				// link.setAttribute('download', isFileType(fileName, type))
				// document.body.appendChild(link)
				// link.click()
				// document.body.removeChild(link)
				try {
					func({ success: true, data: data })
				} catch (error) {
					func({ msg: error.message })
				}
			}).catch(res => { func({ msg: res.msg }) })
		}
		return

		vue.prototype.$reqest = function (opt) {
			opt.url = this.getPath(url)
			opt.header = {
				"Content-Type": "application/x-www-form-urlencoded"
			}
			opt.token = this.$getToken()

			return new Promise((reslove, reject) => {
				uni.request(opt).then(res => {
					let data = res[1].data
					if (data.Success) {
						reslove(data)
					} else {
						reject(data.Message)
					}
				}).catch(res => {
					console.error(res);
					reject('系统繁忙，请稍后重试')
				})
			})
		}

		vue.prototype.$post = function (url, data, func) {
			return this.$reqest({
				url,
				data,
				method: 'POST'
			}).then(res => {
				try {
					func({
						success: true,
						data: res.Data,
						res: res
					})
				} catch (e) {
					console.error(e);
				}
			}).catch(msg => {
				func({
					msg: msg
				})
			})
		}
	}
}
