export default {
	getToken() {
		return this.getString('token')
	},
	setToken(token) {
		return this.set('token', token)
	},
	getString(key) {
		//return uni.getStorageSync(key);
		return localStorage.getItem(key)
	},
	get(key) {
		let res = this.getString(key)
		if (res) {
			res = JSON.parse(res)
		}
		return res
	},
	set(key, value) {
		if (typeof value == 'object') {
			value = JSON.stringify(value)
		}
		// uni.setStorageSync(key, value)
		localStorage.setItem(key, value)
	}
}
