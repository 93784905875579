<template>
	<el-checkbox-group v-model="val" @change="setValue">
		<el-checkbox v-for="(item, index) in options" :key="index" :label="item.value">{{item.label}}</el-checkbox>
	</el-checkbox-group>
</template>

<script>
	import { ref, watch, getCurrentInstance } from "vue";
	export default {
		name: 'echeckbox',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// data: [], 数据源
					// labelKey: '',显示字段名 默认label
					// valueKey: '',实际值字段名 默认value
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
					// bit:true 是否支持位运算
				}
			}
		},
		// data() {
		// 	return {
		// 		val: [],
		// 		options: [],
		// 	};
		// },
		// created() {
		// 	this.init();
		// },
		// watch: {
		// 	value(value) {
		// 		if (!value) {
		// 			this.val = [];
		// 		}
		// 	}
		// },
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			const config = props.config || {}
			var val = ref([])
			let options = ref([])

			const formatValue = () => {
				var v = props.modelValue
				if (proxy.$isEmpty(v)) {
					val.value = [];
				} else if (config.bit) {
					val.value = options.value.filter(c => v & c.value).map(c => c.value)
				} else if (proxy.$isArr(v)) {
					val.value = v.map(c => { return c });
				} else if (proxy.$isStr(v)) {
					val.value = v.split(',').map(c => parseInt(c))
				} else val.value = v
			}
			watch(props, formatValue);

			const loadData = () => {
				if (!config.url) return;
				let q = proxy.$isFunc(config.params) ? config.params() : config.params;
				proxy.$post(config.url, q, ({ data }) => {
					formatData(data);
				});
			}
			const formatData = (data) => {
				if (proxy.$isEmpty(data)) {
					options.value = [];
					return;
				}
				if (proxy.$isFunc(config.filter)) {
					data = config.filter(data);
				}

				let arr = [];
				if (proxy.$isStr(data)) {
					data.split(',').forEach(item => {
						arr.push({
							label: item,
							value: item
						});
					});
				} else {
					data.forEach(item => {
						arr.push({
							label: item[config.labelKey || 'label'],
							value: parseInt(item[config.valueKey || 'value'])
						});
					});
				}
				options.value = arr;
				formatValue()
			}
			const setValue = (value) => {
				var val = 0
				if (config.bit) {
					val = 0
					value.map(c => val += c)
				} else val = value

				emit('update:modelValue', val);
				emit('set', { value: val });
			}
			if (config.url) {
				loadData();
			} else {
				formatData(config.data);
			}
			return { options, val, setValue }
		}
	};
</script>