<template>
	<el-dialog append-to-body modal show-close :top="top" :width="width" :modelValue="modelValue" :title="title"
		draggable destroy-on-close close-on-click-modal :before-close="beforeClose" @close="cancel">
		<!-- <span>{{ title }}</span> -->
		<div style="overflow-y: auto;" :style="{ 'max-height': height }">
			<slot></slot>
		</div>
		<template #footer v-if="actions.length">
			<ebtn @action="click($event, item)" :label="item.label" :icon="item.icon" :type="item.type"
				v-for="item in actions" :key="item.action" ref="btn">
			</ebtn>
		</template>
	</el-dialog>
</template>

<script>
	import { ref } from "vue";
	export default {
		name: 'emodal',
		props: {
			modelValue: {
				type: Boolean
			},
			title: {
				type: String
			},
			width: {
				type: String,
				default: '40%'
			},
			top: {
				type: String,
				default: '100px'
			},
			height: {
				type: String,
				default: '400px'
			},
			/**
			 * type： ok/cancel/custom
			 * label:
			*/
			btns: { type: Array },
			disClose: {//不可关闭
				type: Boolean,
				default: false
			}
		},
		emits: ['submit', 'cancel', 'update:modelValue'],
		setup(props, { emit }) {
			let actions = ref([])
			let show = ref(false)
			let defaultBtn = {
				ok: { label: '确定', action: 'ok', type: 'primary' },
				cancel: { label: '取消', action: 'cancel', type: '' }
			}
			if (props.disClose) {
				actions.value = [defaultBtn.ok]
			} else if (!props.btns) {
				actions.value = [defaultBtn.cancel, defaultBtn.ok]
			} else {
				actions.value = props.btns.map(t => { return Object.assign({}, defaultBtn[t.action], t) });
			}

			const click = (cb, item) => {
				if (item.action == 'ok') {
					emit('submit', cb);
				} else if (item.action == 'cancel') {
					emit('update:modelValue', false);
					cb();
				} else {
					emit(item.action, cb);
				}
			}
			const cancel = () => {
				emit('update:modelValue', false);
				emit('cancel');
			}
			const beforeClose = done => {
				if (props.disClose) return
				done()
			}
			// const submit = () => {
			// 	var a = actions.value.findIndex(c => c.action == 'ok')
			// 	if (a >= 0) {
			// 		console.log(btnRef.value);
			// 	}
			// }
			return { actions, click, cancel, beforeClose };
		}
	};
</script>