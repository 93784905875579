import { createRouter, createWebHistory } from 'vue-router'
import store from './store'
import storage from './base/storage'
import { getRoute } from './base/util'

let arr = [{
  path: '/',
  name: 'home',
  component: '/home/layout',
  meta: { title: '首页', tab: false },
}, {
  path: '/login',
  name: 'login',
  component: '/home/login',
  meta: { auth: false, title: '登录', tab: false },
}, {
  path: '/login/:code',
  name: 'login2',
  component: '/home/login',
  meta: { auth: false, title: '登录', tab: false },
}, {
  path: '/dd',
  name: 'ddlogin',
  component: '/home/dd',
  meta: { auth: false, title: '钉钉自动登录', tab: false },
}, {
  path: '/:pathMatch(.*)',
  name: 'page404',
  component: '/home/404',
  meta: { auth: false, title: '页面不见了', tab: false },
}]

const _home = {
  path: '/',
  name: 'main',
  component: '/home/index',
  meta: { title: '首页', tab: false },
}

//初始化菜单
var menus = []
function initMenu(arr) {
  if (!arr || arr.length == 0) return
  arr.forEach(item => {
    if (item.path) {
      menus.push({
        id: item.id,
        path: item.path,
        meta: { title: item.label, power: item.power },
      })
    } else {
      initMenu(item.children)
    }
  });
}
initMenu(storage.get('menus'))

// menus.push({id:'1',path:'/shop/product/index',meta:{title:'test'}})

arr[0].children = [_home, ...menus]
window.$menus = [_home, ...menus]
store.commit('addTab', _home, true)//默认将首页加入

//如果当前用户没有默认地址的权限 将跳转到首页
if (location.pathname != '/') {
  if (location.pathname.indexOf('login') || location.pathname == '/dd' || location.pathname == '/404') {
  } else if (!menus.some(c => c.path == location.pathname)) {
    window.location.href = '/'
  }
}

arr = getRoute(arr)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: arr
})

//路由拦截
router.beforeEach((to, from, next) => {
  //授权
  if (to.meta.auth != false && !storage.getToken()) {
    if (to.path.indexOf('login') == -1) {
      $toLogin()
      return next({ path: "/login" });
    }
  }
  //tab
  if (to.meta.tab != false) {
    store.dispatch('addTab', to)
  }
  next();
});

export default router