<template>
	<emodal :btns="[{action:'cancel',label:'关闭'}]" v-model="show" width="60%" :title="title">
		<slot>
			<el-descriptions :title="d.title" v-for="(d,index) in config" :key="index" style="margin-bottom: 10px;">
				<el-descriptions-item v-for="item in d.fields" :key="item.label" :label="item.label">
					<el-switch v-if="item.type==1" v-model="item.value" disabled>{{item.value}}</el-switch>
					<div v-else v-html="item.value || '--'"></div>
				</el-descriptions-item>
			</el-descriptions>
		</slot>
	</emodal>
</template>

<script>
	import { ref } from "vue";
	export default {
		name: 'emodalDetails',//弹层详情
		props: {
			value: { type: Boolean },
			title: { type: String },
			config: { type: Array }
		},
		setup(props) {
			let show = ref(props.value)
			return { show }
		}
	};
</script>