<template>
    <el-drawer :title="title" size="35%">
        <el-descriptions :title="d.title" v-for="(d,index) in desc" :key="index" :column="1" border label-align="right"
            align="center" size="large" style="margin-bottom: 10px;">
            <el-descriptions-item v-for="item in d.items" :key="item.label" :label="item.label">
                <el-switch v-if="item.type==1" v-model="item.value" disabled>{{item.value}}</el-switch>
                <span v-else v-html="item.value"></span>
            </el-descriptions-item>
        </el-descriptions>
        <el-table :data="table.data" style="width: 100%" v-if="table.data.length">
            <el-table-column :prop="item.field" :label="item.label" v-for="item in table.cols" :key="item.field" />
        </el-table>
    </el-drawer>
</template>

<script>
    import { ref, getCurrentInstance } from "vue";
    export default {
        name: 'emodelDrawer',//右侧抽屉详情
        props: {
            type: { type: Number },
            params: { type: Object },
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            var title = ref('详情')
            var desc = ref([])
            var table = ref({ data: [], cols: [] })

            const add = (...args) => {
                var t = []
                function g(v) {
                    if (proxy.$isEmpty(v)) return '--'
                    else if (proxy.$isArr(v)) return v.join(',')
                    else return v
                }
                for (let i = 1; i < args.length; i += 2) {
                    t.push({ label: args[i], value: g(args[i + 1]) })
                }
                desc.value.push({ title: args[0], items: t })
            }

            if (props.type == 'kpi_user') {
                title.value = '员工详情'
                proxy.$post('/kpi/user/getDetails', props.params, ({ data }) => {
                    if (!data) return
                    //title.value = `【${data.name}】详情`
                    add('基本信息', '姓名', data.userName, '工号', data.loginId,
                        '状态', data.state, '岗位', data.position,
                        '所属班组', data.groupName, '创建时间', data.createTime)
                });
            } else if (props.type == 'baseuser') {
                title.value = '员工详情'
                proxy.$post('/base/user/getDetails', props.params, ({ data }) => {
                    if (!data) return
                    //title.value = `【${data.name}】详情`
                    add('基本信息', '姓名', data.userName, '工号', data.loginId,
                        '手机号码', data.phone, '身份证号', data.idCard,
                        '用工类型', data.employmentType, '权限类型', data.userType,
                        '员工状态', data.state,)
                });
            } else if (props.type == 'projectbid') {
                title.value = '项目详情'
                proxy.$post('/project/projectbid/info', props.params, ({ data }) => {
                    if (!data) return
                    var steps = data.steps || []
                    var arr = []
                    steps.map(c => {
                        arr.push(c.stepNo)
                        arr.push([c.title, c.stateDesc, c.date1, c.date2, c.remark, c.userNames].filter(c => !!c).join(' '))
                    })
                    add('项目进度', ...arr)

                    var m = data.m || {}
                    //title.value = `【${data.name}】详情`
                    add('基本信息', '项目名称', m.projectName, '项目编号', m.projectCode,
                        '单位名称', m.bidderUnitName,
                        '单位名称1', m.f2,
                        '负责人', m.contact,
                        '采购金额', m.bidderAmountDesc,
                        '采购方式', m.bidderMethodDesc,
                        '招标代理费', m.f1Desc,
                        '项目类别', m.f3,
                        '开标地点', m.f4,
                        '立项时间', m.initDate)
                });
            } else if (props.type == 'cardisorder') {
                title.value = '订单详情'
                proxy.$post('/car/dispatchOrder/getinfo', props.params, ({ data }) => {
                    if (!data) return
                    var fees = data.fees || []
                    var m = data.order || {}
                    var arr = []
                    fees.map(c => {
                        arr.push(c.feeTypeDesc)
                        arr.push([c.remark, c.amountY + '元'].filter(c => !!c).join(' '))
                    })
                    if (data.total) {
                        arr.unshift('车辆结算价', (m.price2 || 0) + '元')
                        arr.push('合计', data.total + '元')
                        add('订单费用', ...arr)
                    }

                    //title.value = `【${data.name}】详情`
                    add('基本信息', '标题', m.title,
                        '车牌号', m.carNo,
                        '司机', `${m.driverName || ''} ${m.driverPhone || ''}`,
                        '状态', m.stateDesc,
                        '人数', m.userCount,
                        '人员信息', m.userNames,
                        '出发地', m.fromAddress,
                        '目的地', m.toAddress,
                        '用车时间', m.startTime,
                        '创建时间', m.createTime,
                        '客户结算价', m.price1 ? (m.price1 + '元') : '')
                });
            }
            return { title, desc, table };
        }
    };
</script>