<template>
	<div v-loading="loading" class="v-table">
		<div class="search" v-if="searchShow">
			<eformBase ref="searchRef" :fields="search" inline size="default" :btns="[]" labelWidth="85px"
				labelSuffix="" seacrhMode label-position="right" @submit="s_submit"></eformBase>
			<ebtn @action="s_submit" icon="iconfont icon-search" title="搜索"></ebtn>
			<ebtn @action="s_clear" type="default" icon="iconfont icon-reload" title="重置"></ebtn>
		</div>

		<div style="margin-bottom: 10px;display: flex;" v-if="cfg.title">
			<slot name="title">
				<span v-text="cfg.title" style="margin-top: 5px;"></span>
			</slot>
			<div style="display: inline-block;flex: 1; text-align: right;">
				<el-dropdown trigger="click" :hide-on-click="false" v-if="!cfg.setColHide" max-height="300">
					<div class="el-dropdown-link table-setting"><i class="iconfont icon-gengduo" title="设置列"></i></div>
					<template #dropdown>
						<el-dropdown-menu>
							<el-checkbox-group v-model="colConfig" :min="1">
								<el-dropdown-item v-for="(col, index) in cols" :key="col.field">
									<el-checkbox :label="col.field" @change="col_config_save">{{ col.label }}
									</el-checkbox>
								</el-dropdown-item>
							</el-checkbox-group>
						</el-dropdown-menu>
					</template>
				</el-dropdown>

				<slot name="tools"></slot>
				<el-button-group>
					<ebtn v-for="item in tools" :key="item.label" :label="item.label" :icon="item.icon"
						@action="tool_action($event, item)"></ebtn>
					<ebtn label="刷新" @action="reload" icon="iconfont icon-reload"></ebtn>
				</el-button-group>
			</div>
		</div>
		<slot name="head"></slot>
		<div class="el-table-box">
			<el-table ref="table" :data="data" stripe border @cell-click="cellClick" @row-dblclick="rowDblclick"
				default-expand-all :row-class-name="rowClass" :row-key="rowKey" highlight-current-row
				:show-summary="sumShow">
				<el-table-column type="selection" header-align="center" align="center" v-if="cbxShow"
					width="55"></el-table-column>
				<el-table-column label="序号" width="60" :resizable="false" align="center" v-if="indexShow">
					<template #default="{ row, $index }">
						{{ $index + 1 + (page.index - 1) * page.size }}
					</template>
				</el-table-column>
				<el-table-column type="expand" v-if="mode == 3">
					<template #default="{ row, $index }">
						<slot name="expand" :row="row" :index="$index">
							{{ row }}
						</slot>
					</template>
				</el-table-column>
				<template v-for="item in cols">
					<el-table-column header-align="center" :show-overflow-tooltip="!item.type" :key="item.field"
						:prop="item.field" :label="item.label" :sortable="item.sort" :width="item.width"
						:align="item.align" v-if="colConfig.includes(item.field)" :mini-width="item.miniwidth">
						<!-- :mini-width="item.width" -->
						<template #header>
							<span v-text="item.label"></span>
							<el-tooltip effect="dark" :content="item.desc" placement="top" v-if="item.desc">
								<i class="iconfont icon-question1" style="font-size: 12px; margin-left:3px"></i>
							</el-tooltip>
						</template>
						<template #default="{ row, $index }">
							<eswitch v-if="item.type == 1" v-model="row[item.field]"
								@set="cell_action($event, item, row)">
							</eswitch>
							<etag v-else-if="item.type == 2" :label="row[item.field]" :tags="item.opt.tags"></etag>
							<eimg v-else-if="item.type == 3" :src="row[item.field]"></eimg>
							<el-link v-else-if="item.drawer && row[item.field]" type="primary"
								@click="drawerShow(item, row)">
								{{ row[item.field] }}</el-link>
							<slot :name="'col_' + item.field" :row="row" :index="$index" v-else>
								{{ row[item.field] || row['_' + item.field] }}
							</slot>
						</template>
					</el-table-column>
				</template>
				<el-table-column label="操作" :resizable="false" header-align="center" :width="operWidth" fixed="right"
					v-if="operWidth">
					<template #default="{ row, $index }">
						<slot name="oper" :row="row" :index="$index">
							<ebtn v-for="item in row.opers" :key="item" :label="opers[item].label"
								@action="oper_action($event, item, row)" link></ebtn>
						</slot>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination background layout="slot,total,sizes,prev,pager,next,jumper" :page-size="page.size"
			:current-page="page.index" :total="page.total" @size-change="changePageSize" @current-change="changePage"
			:page-sizes="[10, 15, 30, 50, 100, 200]" v-if="page.total">
			<!-- <el-select v-model='export_type' placeholder="导出" class="el-border-button" @change="test">
				<el-option key="1" value="1" label="导出">导出当前页</el-option>
				<el-option key="2" value="2" label="导出" v-text="">导出所有页</el-option>
			</el-select>-->
			<!-- <el-dropdown split-button type="primary" @command="export_change_type" @action="a_export"
				style="background-color: #409EFF;" v-loading.fullscreen.lock="exp.ing">
				{{exp.type==1?'导出当前':'导出全部'}}
					<template #dropdown>
				<el-dropdown-menu >
					<el-dropdown-item command="1">导出当前</el-dropdown-item>
					<el-dropdown-item command="2">导出全部</el-dropdown-item>
				</el-dropdown-menu>
				</template>
			</el-dropdown> -->
		</el-pagination>
		<!--导入-->
		<emodalImport v-bind="imp" @success="imp_success" v-model="impShow" v-if="impShow">
		</emodalImport>
		<!--新增-->
		<emodalForm :config="e" @submit="e_save" v-model="eShow" v-if="eShow"></emodalForm>
		<!--详情-->
		<emodelDrawer :type="d.type" :params="d.params" v-model="dShow" v-if="dShow">
		</emodelDrawer>
	</div>
</template>

<script>
	var _query = {};
	var _query_default = {}//默认参数 不会被清空的
	var _saveQuery = {} //弹层操作的参数
	var _selected = [] //选中行数据
	var _action = null //当前操作
	var _actionRows = {} //操作对象
	export default {
		name: 'etable',
		props: {
			cfg: {
				type: [Object, String]
			}
		},
		data() {
			return {
				loading: false,
				page: {
					index: 1,
					size: 15,
					total: 0
				},
				mode: 1,//模式 1普通表格 2树形表格 3展开行
				rowKey: '',
				cols: [], //列字段
				tools: [], //工具栏按钮
				opers: [], //操作栏按钮
				operWidth: '', //操作栏宽度
				data: [], //数据源
				editCols: {}, //编辑字段
				search: {}, //搜索栏
				colConfig: [], //列配置
				searchShow: false,//是否显示搜索栏
				indexShow: true,//是否显示行号栏
				cbxShow: true,//是否显示选择栏
				sumShow: false,//是否显示合计

				imp: {},//导入
				impShow: false,
				e: {},//修改
				eShow: false,
				d: {},//详情
				dShow: false
			};
		},
		created() {
			_query = {}
			_saveQuery = {}
			_selected = []
			_action = null
			_actionRows = {}
			_query_default = {}
			this.getConfig();
		},
		methods: {
			getConfig() {
				var c = this.cfg || {}
				var cfg = c.cfg
				if (!cfg) {
					this.init(c);
					return
				}
				if (typeof cfg == 'object') {
					this.parseConfig(c, cfg.cols, cfg.form, cfg.search)
					return
				}
				this.$post(cfg, {}, ({ data }) => {
					if (!data) return
					this.parseConfig(c, data.cols, data.form, data.search)
				})
			},
			parseConfig(cfg, cols, forms, searchs) {
				cols = cols || []
				forms = forms || []
				searchs = searchs || []
				var c = cfg
				c.cols = {}
				c.edits = {}
				c.searchs = {}
				for (let i = 0; i < cols.length; i++) {
					const t = cols[i];
					var m = {
						label: t.label,
						type: t.type,
						sort: t.sort,
						format: t.format,
						width: (cfg.width || [])[i],
						opt: t.options || {},
						drawer: (cfg.drawer || {})[t.field]
					}
					if (m.type == 1) {
						m.action = t.options || {}
						m.opt = {}
					} else if (t.type == 0 && t.type2 == 1) {
						m.format = 'price'
					}
					c.cols[t.field] = m
				}
				//edit
				for (let i = 0; i < forms.length; i++) {
					const t = forms[i];
					var m = {
						label: t.label,
						type: t.type,
						required: t.required,
						opt: t.options || {}
					}
					if (t.type == 2) {
						if (t.type2 == 1) {//下拉树
							m.type = 202
						} else if (t.type2 == 2) {//字典
							m.opt.url = '/common/dict'
							m.opt.labelKey = 'title'
						} else if (t.type2 == 3) {//枚举
							m.opt.url = '/common/enums'
						}
						m.opt.params = Object.assign({ type: t.fkey }, cfg.query)
					} else if (t.type == 3) {
						if (t.type2 == 1) {//枚举
							m.opt.url = '/common/enums'
						}
						m.opt.params = Object.assign({ type: t.fkey }, cfg.query)
					} else if (t.type == 4) {
						if (t.type2 == 2) {//枚举
							m.opt.url = '/common/enums'
							m.opt.bit = true
						}
						m.type = 4
						m.opt.params = Object.assign({ type: t.fkey }, cfg.query)
					} else if (t.type == 7) {
						if (t.type2 == 1) {//日期时间
							m.opt.type = 'datetime'
						} else if (t.type2 == 2) {//月份
							m.opt.type = 'month'
						}
						m.opt.params = Object.assign({ type: t.fkey }, cfg.query)
					} else if (t.type == 8) {//上传
						m.type = 6
						m.opt.limit = 1
					} else if (!t.type) {
						m.opt.placeholder = m.opt.placeholder || ('请输入' + m.label)
						if (t.type2 == 5) {
							m.format = 'price'
						}
					}
					if (t.valid) {
						m.min = t.valid.min
						m.max = t.valid.max
						m.minVal = t.valid.minVal
						m.maxVal = t.valid.maxVal
						m.reg = t.valid.regex
					}
					c.edits[t.field] = m
				}
				//search
				searchs = searchs || []
				_query_default = cfg.default || {}//默认参数
				for (let i = 0; i < searchs.length; i++) {
					const t = searchs[i];
					var m = {
						label: t.label,
						type: t.type,
						opt: t.options || {}
					}
					if (t.type == 2) {
						if (t.type2 == 1) {
							m.opt.data = [{ label: '启用', value: 1 }, { label: '禁用', value: 0 }]
						} else if (t.type2 == 2) {//字典
							m.opt.params = { type: t.fkey }
							m.opt.url = '/common/dict'
							m.opt.labelKey = 'title'
						} else if (t.type2 == 3) {//枚举
							m.opt.params = { type: t.fkey }
							m.opt.url = '/common/enums'
						} else if (t.type2 == 4) {//下拉树
							m.type = 202
						}
						//m.opt.multiple = m.opt.multiple === false ? false : true
					} else if (t.type == 7) {
						if (t.type2 == 1) {
							m.type = 702
						} else if (t.type2 == 2) {
							m.opt.type = 'month'
							if (!_query_default[t.field])
								_query_default[t.field] = new Date().format('yyyy-MM')
						}
					}
					if (_query_default[t.field])
						m.value = _query_default[t.field]
					c.searchs[t.field] = m
					_query = { ..._query_default }
				}
				this.init(c);
			},
			init(config) {
				let _cols = [];
				let _colConfig = [];
				let _search = {};
				let cols = config.cols || {};
				this.page.size = config.pageSize || this.page.size;
				let colConfig = this.col_config_get(cols)
				this.rowKey = config.rowKey || 'id'
				this.mode = config.mode || 1
				this.indexShow = this.mode == 1
				this.sumShow = !!config.sum
				this.cbxShow = config.cbxShow == undefined ? this.mode == 1 : config.cbxShow

				config.before && config.before(config)

				//cols
				for (let key in cols) {
					if (cols.hasOwnProperty(key)) {
						let item = cols[key];
						_cols.push({
							label: item.label,
							desc: item.desc,
							field: key,
							sort: this.mode == 1 && item.sort,
							type: item.type,
							format: item.format,
							action: item.action,
							// export: item.export || {},
							align: item.type ? 'center' : 'left',
							opt: item.opt || {},
							drawer: item.drawer,
							...this.get_width_default(item)
							// ext: item.ext,
						});

						// if (item.edit) {
						// 	_editCols[key] = Object.assign({ sort: 99 }, item.edit, { label: item.label });
						// }
						if (item.search) {//_editCols[key],
							_search[item.search.field || key] = this.get_search_default(Object.assign({ label: item.label }, { placeholder: '' }, item.search))
						}
						if (!colConfig.includes(key)) {
							_colConfig.push(key);
						}
					}
				}
				// _cols[_cols.length - 1].width = ''
				var s = config.searchs || _search
				this.cols = _cols;
				this.search = s;
				this.searchShow = Object.keys(s).length;
				this.editCols = config.edits;
				this.colConfig = _colConfig;

				//tool
				this.tools = this.get_action_default(config.tools);
				//oper
				this.opers = this.get_action_default(config.opers);

				if (config.operWidth) {
					this.operWidth = config.operWidth
				} else {
					let operWidth = 0;
					this.opers.map(a => {
						operWidth += (a.label || '').length * 15 + 15;
					});
					this.operWidth = operWidth ? operWidth + 35 : 0;
				}
				this.load();
			},
			//加载数据
			load() {
				let q = this.load_get_param(this.page.size)
				if (!q) return

				this.loading = true;
				this.load_get_data(q, (items, total) => {
					this.set_data(items);
					this.page.total = total || 0
					this.loading = false;
					this.$emit('load')
				})
			},
			load_get_param(limit) {
				let q = {
					pageIndex: this.page.index,
					pageSize: limit
				};
				q = Object.assign({}, _query, this.cfg.query, q);
				if (this.cfg.beforeLoad) {
					q = this.cfg.beforeLoad(q)
					if (!q) return
				}
				for (const key in q) {
					if (Object.hasOwnProperty.call(q, key)) {
						if (Array.isArray(q[key])) {
							q[key] = q[key].join(',');
						}
					}
				}
				return q
			},
			load_get_data(q, func) {
				if (!this.cfg.url) {
					func(this.cfg.data || [])
					return
				}

				this.$post(this.cfg.url, q, ({ data }) => {
					data = data || [];
					let items = []
					if (this.cfg.afterLoad) {
						items = this.cfg.afterLoad(data)
					} else {
						items = data.items || (Array.isArray(data) ? data : [])
					}
					items = items || []

					if (this.mode == 2) {
						func(items.toTree())
					} else {
						func(items, data.total)
					}
				});
			},
			set_data(data) {
				data = data || []
				data.treeMap && data.treeMap(item => {
					item.id = item.id || item[this.rowKey]
					item.opers = [];
					for (let j = 0; j < this.opers.length; j++) {
						let t = this.opers[j];
						if (!t.show || t.show(item)) {
							item.opers.push(j);
						}
					}
					this.cols.forEach(c => {
						if (item[c.field] == undefined && !c.drawer) {
							item['_' + c.field] = ''
						} else if (c.format == 'price') {
							item[c.field] = this.$price((item[c.field] || 0), true) || ''
						} else if (c.format == 'month') {
							item[c.field] = this.$month(item[c.field] || 0) || ''
						} else if (c.format == 'array') {
							item[c.field] = item[c.field].join()
						} else {
							item['_' + c.field] = this.$isEmpty(item[c.field]) ? '' : item[c.field]
						}
					});
				})
				this.data = data;
			},
			//search
			s_submit(cb) {
				try {
					if (this.$isFunc(cb)) cb()
					_query = this.$copy(this.$refs.searchRef.model);
					this.toFirstPage();
				} catch (error) {
					console.error(error);
				}
			},
			s_clear(cb) {
				cb();
				this.$refs.searchRef.cancel(null, _query_default)
			},
			//获取排除的列
			col_config_get(cols) {
				var key = this.$route.fullPath.replaceAll('/', '')
				var arr = this.$storage.get('col_' + key) || [];
				if (arr.length) return arr;

				arr = []
				for (const k in cols) {
					if (Object.hasOwnProperty.call(cols, k)) {
						var t = cols[k]
						if (t.opt && t.opt.hide) {
							arr.push(k)
						}
					}
				}
				return arr
			},
			col_config_save() {
				// if (this.colConfig.length == 0) {
				// 	this.$err('请至少保留一列')
				// 	this.colConfig = this.col_config_get()
				// 	return
				// }
				//获取排除的列
				var key = this.$route.fullPath.replaceAll('/', '')
				var arr = this.cols.filter(c => this.colConfig.indexOf(c.field) < 0).map(c => c.field)
				this.$storage.set('col_' + key, arr);
			},
			changePage(page) {
				this.page.index = page;
				this.load();
			},
			changePageSize(size) {
				this.page.size = size;
				this.page.index = 1;
				this.load();
			},
			reload(t) {
				if (this.$isFunc(t)) {
					this.load();
					t()
				} else if (this.$isStr(t)) {
					this.tipsAndReload(t)
				} else if (this.$isObj(t)) {
					_query = Object.assign(_query, t)
					this.load()
				} else {
					this.load()
				}
			},
			tipsAndReload(msg) {
				this.$tips(msg);
				setTimeout(() => {
					this.reload()
				}, 200);
			},
			toFirstPage(cb) {
				this.page.index = 1
				this.load();
				cb && cb();
			},
			//action
			tool_action(cb, item) {
				// if (item.action) {
				// 	cb()
				// 	return this[item.action]()
				// }
				//是否需要选择行 true只能选择一个,false可以选择1个或多个,undefined不限制
				let vals = []
				if (item.label != '新增')
					vals = this.get_selected();
				if (item.single === false) {
					if (vals.length == 0) {
						this.$err('请选择要操作的记录', cb);
						return;
					}
				} else if (item.single === true) {
					if (vals.length == 0) {
						this.$err('请选择要操作的记录', cb);
						return;
					} else if (vals.length > 1) {
						this.$err('只能选择一条记录', cb);
						return;
					}
				} else {
					// vals = []
				}
				_actionRows = vals;
				this.a_base(item, {
					cb, rows: vals, tool: true
				});
			},
			oper_action(cb, index, row) {
				_actionRows = [row];
				this.a_base(this.opers[index], { cb, row });
			},
			cell_action({ value, cancel }, item, row) {
				_actionRows = [row]
				this.a_base(item.action, {
					type: 3,
					param: {
						[item.field]: row[item.field]
					},
					cb: (res) => {
						if (!res) cancel()
					}
				})
			},
			a_base(a, opt) {
				if (!a) {
					opt.cb && opt.cb()
					console.log('未知的key');
					return
				}
				//权限校验
				if (a.auth && !this.$hasAuth(a.auth)) {
					opt.cb && opt.cb()
					return
				}
				if (a.action) {
					this[a.action](a, opt)
				} else if (a.custom) {
					this.a_custom(a, opt)
				} else if (a.type == 1) {
					this.a_modal(a, opt);
					opt.cb && opt.cb()
				} else if (a.type == 3) {
					this.a_page(a)
					opt.cb && opt.cb()
				} else {
					this.a_ajax(a, opt)
				}
				_action = a
			},
			a_ajax(a, { row, param, cb, type }) {
				let tips
				if (a.tips) tips = this.$isFunc(a.tips) ? a.tips(row, _actionRows) : a.tips.formatModel(row)

				this.$conf(tips, () => {
					let q = this.get_action_params(a);
					q = Object.assign({}, q, param);
					this.$post(a.url, q, ({ success, msg, data }) => {
						if (success) {
							cb && cb(true)
							if (type == 3) this.reload()
							else this.tipsAndReload(msg || '操作成功')
						} else {
							cb && cb()
							this.$err(msg);
						}
					});
				}, () => { cb && cb(1) });
			},
			a_modal(a, opt) {
				opt = opt || {}
				let cur = this.$copy(_actionRows[0]);
				if (a.auth == '新增' && opt.tool) {
					cur = {}
				}
				var config = {
					values: {},
					col: a.col,
					title: a.title || a.label,
					fields: a.fields || this.editCols || {}
				}
				if (a.before) {
					a.before(config, cur)
				}
				if (!config.col) {
					config.col = Object.keys(config.fields).length >= 5 ? 2 : 1;
				}

				this.e = config
				//默认值
				if (a.data != undefined) {
					if (this.$isFunc(a.data)) {
						//异步 
						let p = new Promise(function (reslove, reject) {
							let res = a.data(cur, reslove, reject)
							if (!(res instanceof Promise)) {
								reslove(res)
							}
						})
						p.then(result => {
							this.e.values = Object.assign({}, cur, result);
							this.eShow = true;
						}, err => {
							this.$err(err)
						});
					} else if (this.$isStr(a.data)) {
						this.$post(a.data, { id: cur.id }, ({ data }) => {
							this.a_modal_format(config.fields, data)
							this.e.values = Object.assign({}, cur, data);
							this.eShow = true;
						});
					} else {
						this.e.values = cur;
						this.eShow = true;
					}
				} else this.eShow = true;
			},
			a_modal_format(fields, row) {
				for (const key in fields) {
					if (Object.hasOwnProperty.call(fields, key)) {
						const c = fields[key];
						if (c.format == 'price') {
							row[key] = this.$price((row[key] || 0), true) || ''
						} else if (key == 'iMonth') {
							var s = row[key] + '' || ''
							row[key] = new Date(s.slice(0, 4), s.slice(4) - 1, 1).format('yyyy-MM')
						}
					}
				}
			},
			e_save(q, cb) {
				let a = _action;
				q = this.get_action_params(a, q);
				this.$post(a.url, q, ({ success, msg, data }) => {
					cb && cb()
					if (success) {
						this.eShow = false;
						if (a.cb) {
							this.$emit(a.cb, { data, url: a.url, query: q })
						} else {
							this.tipsAndReload(msg || '保存成功')
						}
					} else {
						this.$err(msg || '保存失败');
					}
				});
			},
			a_custom(a, { cb, row, rows }) {
				let data = this.copy_data(_actionRows);
				// 处理参数
				if (row) _saveQuery = { id: row.id }
				else if (rows) _saveQuery = { ids: rows.map(c => c.id).join() }
				else _saveQuery = {}

				var q = { query: { ..._saveQuery, ..._query }, row, rows, data, action: a.custom, cb }
				this.$emit('action', q);
				this.$emit(a.custom, q)
			},
			save(url, param, func, cb) {
				param = Object.assign({}, param, _saveQuery)
				this.$post(url, param, ({ success, msg, data }) => {
					if (success) {
						func && func()
						this.tipsAndReload('操作成功');
					} else {
						this.$err(msg);
					}
					cb && cb()
				});
			},
			a_page(item) {
				let q = this.get_action_params(item);
				this.$to2({ path: item.path, query: q, meta: { title: item.title } });
			},
			a_export(item, { cb }) {
				try {
					let q = { ids: this.get_selected('id').join() }
					q = Object.assign(q, this.load_get_param())
					this.$post(item.url, q, res => {
						cb && cb()
						if (res.success) {
							if (res.data && res.data.down) {
								this.$downFile('/file/downFile', res.data.down)
							}
							// this.$tips('文件导出成功');
						} else {
							this.$err(res.msg);
						}
					});
					// exportFile(data.length ? data : this.get_data())
				} catch (error) {
					// this.exp.ing = false
					cb && cb()
				}
			},
			a_import(item, { cb }) {
				this.impShow = true
				this.imp = {
					params: this.load_get_param(),
					template: item.template,
					url: item.url,
					config: item.opt || {}
				}
				cb && cb()
			},
			imp_success() {
				this.reload();
				this.impShow = false;
			},
			drawerShow(field, row) {
				if (field.drawer.custom) {
					this.$emit(field.drawer.custom, { row, field })
					return
				}
				var f = field.drawer.field || 'id'
				this.d = {
					type: field.drawer.type,
					params: {
						id: row[f]
					}
				}
				this.dShow = true;
			},
			get_action_params(action, q) {
				q = q || {};
				if (this.$isFunc(action.params)) {
					debugger
					q = action.params(q, _actionRows, this.cfg.query);
				} else if (!this.$isObj(action.params)) {
					q[action.params || 'id'] = _actionRows.map(c => { return c.id; }).join();
				}
				return Object.assign({}, this.load_get_param(), q, this.cfg.query);
			},
			get_action_default(data) {
				let res = [];
				if (!data) return res;
				for (var i = 0; i < data.length; i++) {
					let action = data[i];
					//内置
					let icons = {
						新增: 'icon-roundadd',
						修改: 'icon-edit',
						删除: 'icon-delete',
						启用: 'icon-order',
						禁用: 'icon-order',
						导入: 'icon-upload',
						导出: 'icon-down',
						配置: 'icon-settings_light'
					};
					//type：1弹出层 2ajax（默认） 3新页面
					let defaultOpt = {
						add: { type: 1, params: {}, label: '新增' },
						edit: { type: 1, label: '修改', data: '' },
						del: { label: '删除', tips: '是否确定要删除该记录？' },
						export: { label: '导出', action: 'a_export', params: 'ids' },
						import: { label: '导入', action: 'a_import' },
						batchDel: { params: 'ids', single: false, label: '批量删除', tips: '是否确定要删除选中的记录？' },
					};
					let defaultVal = defaultOpt[action.type];
					if (defaultVal) {
						var auth = defaultVal.label.replace('批量', '')

						delete action.type
						action = Object.assign({ icon: icons[auth], auth }, defaultVal, action);
						// if (action.action) {
						// 	action.type = action.action
						// 	delete action.action
						// } else {
						// 	action.type = defaultVal.type;
						// }
					}

					if (!action.icon && action.label) {
						action.icon = icons[action.label.replace('批量', '')];
					}
					if (action.icon) {
						action.icon = 'iconfont ' + action.icon
					}
					if (action.auth != false) {
						if (!this.$hasAuth(action.auth || action.label)) continue;
					}
					res.push(action);
				}
				return res;
			},
			get_width_default(item) {
				if (!!item.width) return { width: item.width };

				if (item.type == 1) return { width: 100 }//开关
				else if (item.type == 2) return { width: 110 }//标签
				else if (item.type == 3) return { width: 100 }//图片

				if (item.label.length >= 5) {
					return { miniwidth: 130 };
				}
				if (item.label.length >= 4) {
					return { width: 120, miniwidth: 100 };
				}
				// else if(item.label.indexOf('时间')){
				// 	return 160;
				// } 
				// else if (item.label.length >= 3) {
				// 	return 120;
				// }
			},
			get_search_default(field) {
				if (field.type == 5) {
					field.type = 2;
					field.opt = Object.assign({ data: [{ label: '启用', value: true }, { label: '禁用', value: false }] })
				} else if (field.type == 1 || !field.type) {
					var opt = field.opt || {}
					field.opt = Object.assign({ placeholder: '请输入' + field.label }, opt)
				}
				return field
			},
			//选择行
			copy_data(data) {
				let to = c => {
					let t = this.$copy(c)
					t.Btns = undefined
					t.opers = undefined
					return t
				}
				if (this.$isArr(data)) {
					return data.map(to)
				} else {
					return to(data)
				}
			},
			get_selected(key) {
				var arr = this.$refs.table.getSelectionRows();
				if (key) {
					return arr.map(c => {
						return c[key];
					});
				} else {
					return this.$copy(arr)
				}
			},
			get_data() {
				return this.$copy(this.data)
			},
			//event
			// selectionChange(items) {
			// 	_selected = items;
			// },
			cellClick(row, cell, el) {
				this.$refs.table.clearSelection()
				this.$refs.table.toggleRowSelection(row, true)
			},
			rowClass({ row }) {
				if (this.cfg.rowClass) {
					return this.$isFunc(this.cfg.rowClass) ? this.cfg.rowClass(row) : this.cfg.rowClass
				}
				// if (_selected && _selected.includes(row)) {
				// 	return 'slecleRowColor'
				// }
				return ''
			},
			rowDblclick(row) {
				if (this.cfg.details == 'custom') {//自定义
					this.$emit('action', { data: this.copy_data(row), type: 'details', cb: function () { } });
				} else if (this.cfg.details) {
					let that = this
					let exec = function (config) {
						that.details.show = true;
						that.details.config = config
					}
					if (this.$isFunc(this.cfg.details)) {
						Promise.all([this.cfg.details(row)]).then(result => {
							exec(result[0])
						});
					} else {
						let fields = this.cols.map(c => {
							return {
								type: c.type,
								label: c.label,
								value: row[c.field]
							}
						})
						exec([{ title: '基本信息', fields }])
					}
				}
			},
		}
	};
</script>