<template>
	<el-radio-group v-model="val" @change="setValue">
		<el-radio v-for="(item, index) in options" :key="index" :label="item.value">{{item.label}}</el-radio>
	</el-radio-group>
</template>

<script>
	import { ref, watch, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'eradio',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// data: [], 数据源
					// labelKey: '',显示字段名 默认label
					// valueKey: '',实际值字段名 默认value
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
				}
			}
		},
		// data() {
		// 	return {
		// 		val: '',
		// 		options: []
		// 	};
		// },
		// created() {
		// 	this.init();
		// },
		// watch: {
		// 	value(value) {
		// 		if (!value) {
		// 			this.val = '';
		// 		}
		// 	}
		// },
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let config = props.config || {}
			let loading = ref(false);
			let options = ref([])
			let val = ref('')

			// const formatValue = () => {
			// 	var v = props.modelValue
			// 	if (multiple.value) {
			// 		if (proxy.$isEmpty(v)) {
			// 			val.value = [];
			// 		} else if (proxy.$isArr(v)) {
			// 			val.value = v.map(c => { return c + '' });
			// 		} else {
			// 			val.value = (v + '').split(',');
			// 		}
			// 	} else {
			// 		val.value = proxy.$isEmpty(v) ? '' : v + '';
			// 	}
			// }
			const loadData = () => {
				if (!config.url) return;
				loading.value = true
				let q = proxy.$isFunc(config.params) ? config.params() : config.params;
				proxy.$post(config.url, q, ({ data }) => {
					formatData(data);
					loading.value = false
				});
			}
			const formatData = (data) => {
				if (proxy.$isEmpty(data)) {
					options.value = [];
					return;
				}
				if (proxy.$isFunc(config.filter)) {
					data = config.filter(data);
				}

				data.forEach(item => {
					item.label = item[config.labelKey || 'label']
					item.value = item[config.valueKey || 'value'] + ''
				});
				options.value = data;

				val.value = (proxy.$isEmpty(props.modelValue) ? '' : props.modelValue) + '';
			}
			const setValue = (value) => {
				emit('update:modelValue', value);
				emit('set', { value });
			}
			config.url ? loadData() : formatData(config.data);

			return { options, loading, val, setValue }
		},
	};
</script>