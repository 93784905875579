<template>
	<el-button type="primary" link>
		<slot></slot>
	</el-button>
</template>

<script>
	export default {
		name: 'elink',
		props: {
		},
		data() {
			return {
			};
		},
		methods: {
		}
	};
</script>