<template>
    <el-tree-select ref="sel" node-key="id" v-model="val" :placeholder="config.placeholder" @change="setValue"
        :data="options" :multiple="multiple" :clearable="true" :check-strictly="true" :default-expand-all="true"
        :expand-on-click-node="false" collapse-tags :disabled="config.disabled" />
</template>

<script>
    import { ref, watch, reactive, getCurrentInstance } from "vue";
    export default {
        name: 'eselectTree',
        props: {
            modelValue: {},
            config: {
                type: Object,
                default: {
                    // data: [], 数据源
                    // labelKey: '',显示字段名 默认label
                    // valueKey: '',实际值字段名 默认value
                    // url: '',请求地址
                    // params: '',请求参数 object/func
                    // filter:null,数据源过滤方法 func
                    // multiple: false,是否多选
                    // placeholder:'' 提示语
                    // disabled
                }
            }
        },
        setup(props, { emit }) {
            let config = props.config || {}
            const { proxy } = getCurrentInstance();
            let loading = ref(false);
            let multiple = ref(!!config.multiple)
            let options = ref([])
            let val = ref('');

            watch(props, (newValue, oldValue) => {
                if (!newValue.modelValue) {//清空
                    val.value = multiple.value ? [] : '';
                }
            });

            const loadData = () => {
                if (!config.url) return;
                loading.value = true
                let q = proxy.$isFunc(config.params) ? config.params() : config.params
                proxy.$post(config.url, q, ({ data }) => {
                    formatData(data);
                    loading.value = false
                });
            }
            const formatData = (data) => {
                if (proxy.$isEmpty(data)) {
                    options.value = [];
                    return;
                }
                if (proxy.$isFunc(config.filter)) {
                    data = config.filter(data);
                }

                var formatData = (item) => {
                    item.label = item[config.labelKey || 'name'] || item.label || item.title
                    item.value = (item[config.valueKey || 'id'] || item.value)
                }

                data.forEach(item => {
                    formatData(item)
                    if (item.children)
                        item.children.forEach(formatData)
                });
                var type = data.length ? typeof data[0].value : 'string'
                options.value = data.formatAndToTree(config);

                var v = props.modelValue
                if (multiple.value) {
                    if (proxy.$isEmpty(v)) {
                        val.value = [];
                    } else if (proxy.$isArr(v)) {
                        val.value = v.map(c => { return c + '' });
                    } else {
                        val.value = (v + '').split(',');
                    }
                } else {
                    val.value = type == 'number' ? (parseInt(v) || '') : (v || '');
                }
            }
            const setValue = (value) => {
                emit('update:modelValue', value);
                emit('set', { value: proxy.$isArr(value) ? value.join() : value });
            }
            (function () {
                if (config.url) {
                    loadData();
                } else {
                    formatData(config.data);
                }
            })()

            return { options, multiple, loading, val, setValue }
        },
    };
</script>