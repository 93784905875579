
if (typeof String.prototype.replaceAll === "undefined") {
    String.prototype.replaceAll = function (match, replace) {
        return this.replace(new RegExp(match, 'g'), () => replace);
    }
}
if (!Array.prototype.flatMap) {
    Array.prototype.flatMap = function (callback, thisArg) {
        return Array.prototype.map.call(this, callback, thisArg)
            .reduce((acc, val) => acc.concat(val), []);
    };
}
window.$toLogin = function () {
    var url = localStorage.getItem('logint')
    window.location.href = url || '/login'
}

export default {
    install(app) {
        Date.prototype.format = function (fmt) { //author: meizz 
            if (!this.getTime()) return ''
            fmt = fmt || 'yyyy-MM-dd'
            var o = {
                "M+": this.getMonth() + 1, //月份 
                "[dD]+": this.getDate(), //日 
                "h+": this.getHours(), //小时 
                "H+": this.getHours(), //小时 
                "m+": this.getMinutes(), //分 
                "s+": this.getSeconds(), //秒 
                "S": this.getMilliseconds() //毫秒 
            };
            if (/([yY]+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));

            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((
                    "00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        };
        Date.prototype.s = function () {
            return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0)
        }
        Date.prototype.e = function () {
            return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 23, 59, 59)
        }

        Number.prototype.price = function () {
            if (!this) return this
            var str = (this / 100).toFixed(2) + '';
            var intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ',');//取到整数部分
            var dot = str.substring(str.length, str.indexOf("."))//取到小数部分搜索
            var ret = intSum + dot;
            return ret;
        }

        //格式化字符串
        String.prototype.formatModel = function (model) {
            return this.replace(/\{([\w\.]+)\}/gi, function (all, key) {
                var funcStr = 'return _$0.' + key;
                var func = new Function('_$0', funcStr)
                var str = func(model);

                if (str === undefined || str === null) {
                    return "";
                }
                return str;
            });
        }
        String.prototype.format = function () {
            if (typeof arguments[0] == 'object') return this.formatModel(arguments[0])
            var str = this;
            for (var i = 0; i < arguments.length; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "g");
                str = str.replace(reg, arguments[i] == undefined || arguments[i] == null ? "" : arguments[i]);
            }
            return str;
        }

        Array.prototype.max = function () {
            return Math.max.apply(Math, this);
        };
        Array.prototype.min = function () {
            return Math.min.apply(Math, this);
        };

        Array.prototype.treeMap = function (func) {
            if (!this || this.length == 0) return

            var f = (item) => {
                func(item)
                if (item.children) {
                    item.children.forEach(f);
                }
            }
            this.forEach(f);
        }

        Array.prototype.toTree = function () {
            if (!this || this.length == 0) return []

            if (this[0].children) return this

            // this.forEach(function (item) {
            //     delete item.children;
            // });
            var map = {};
            this.forEach(function (item) {
                map[item.id] = item;
            });
            var val = [];
            this.forEach(function (item) {
                var parent = map[item.pid || item.pId || item.parentId];
                if (parent) {
                    (parent.children || (parent.children = [])).push(item);
                    parent.children = parent.children.sort((a, b) => a.sortOrder - b.sortOrder)
                } else {
                    val.push(item);
                    val = val.sort((a, b) => a.sortOrder - b.sortOrder)
                }
            });
            return val;
        }

        Array.prototype.formatAndToTree = function (config) {
            config = config || {}
            var res = this
            if (config.format) {
                res = config.format(res)
            } else {
                res.forEach(t => {
                    t.id = t[config.valueKey || 'id']
                    t.label = t[config.labelKey || 'name'] || t.label
                    t.pid = t[config.pidKey || 'pId'] || t.pid || t.parentId
                    t.sort = t[config.sortKey || 'sortOrder'] || t.sortNumber
                });
            }
            res = res.toTree()
            function f(c, i) {
                if (c.children) {
                    c.children = c.children.sort((a, b) => a.sort - b.sort);
                    c.children.forEach((_c, _i) => {
                        if (!c.canDel) {
                            _c.canDel = false
                        }
                        f(_c, _i)
                    });
                }
            }
            res.forEach(f);
            return res.sort((a, b) => a.sort - b.sort);
        }

        RegExp.const = {
            number: {
                reg: /^(0|[1-9][0-9]{0,8})$/,
                msg: '应为大于0的整数'
            },
            digit: {
                reg: /^(-?(0|[1-9]\d*))$/,
                msg: '格式有误'
            },
            float: {
                reg: /^(0|[1-9][0-9]{0,8})(.[0-9]{1,2})?$/,
                msg: '仅支持整数或小数'
            },
            price: {
                //reg: /^(0|[1-9][0-9]{0,8})(.[0-9]{1,2})?$/,
                reg: /^[1-9]\d{0,2}(,?\d{3})*(\.\d{1,2})?$/,
                msg: '金额格式有误'
            },
            code: {
                reg: /^[a-zA-Z0-9_]{2,30}$/,
                msg: '格式不正确'
            },
            phone: {
                reg: /^1\d{10}$/,
                msg: '手机号码格式不正确'
            },
            email: {
                reg: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                msg: '邮箱地址格式不正确'
            },
        }

        // app.config.globalProperties.getRoute = (data) => {
        //     function get(c) {
        //         if (!c.name) {
        //             c.name = c.path.replaceAll('/', '')
        //         }
        //         let path = c.component || c.path
        //         if (path) {
        //             // c.component = resolve => require(["../../pages" + path], resolve);
        //             c.component = () => import("@/pages" + path)
        //         }
        //         if (!c.meta) {
        //             c.meta = {}
        //         }
        //     }

        //     let temp = []
        //     if (Array.isArray(data)) {
        //         temp = data
        //     } else {
        //         temp = [data]
        //     }
        //     temp.forEach(c => {
        //         get(c)
        //         if (c.children) {
        //             c.children.forEach(get);
        //         }
        //     });
        //     return data
        // }

        app.config.globalProperties.$copy = (obj) => {
            if (!obj) return obj
            if (typeof obj == 'object') return JSON.parse(JSON.stringify(obj))
            return obj
        }
        app.config.globalProperties.$isObj = (obj) => {
            return typeof obj == 'object'
        }
        app.config.globalProperties.$isArr = (obj) => {
            return Array.isArray(obj)
        }
        app.config.globalProperties.$isEmpty = (obj) => {
            if (obj === undefined || obj === null || obj === '') {
                return true
            } else if (Array.isArray(obj)) {
                return obj.length == 0
            }
        }
        app.config.globalProperties.$isFunc = (obj) => {
            return typeof obj == 'function'
        }
        app.config.globalProperties.$isStr = (obj) => {
            return typeof obj == 'string'
        }
        app.config.globalProperties.$toYuan = (v, p) => {
            if (isNaN(Number(v))) return 0
            var num = Number(v) / 100
            if (p) return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return num
        }
        app.config.globalProperties.$toFen = (v) => {
            if (isNaN(Number(v))) return 0
            var s = v + ''
            var index = s.indexOf('.')
            if (index >= 1) {
                var arr = s.split('.')
                if (arr[1].length == 2) {
                    return parseInt(s.replace('.', ''))
                } else if (arr[1].length == 1) {
                    return parseInt(s.replace('.', '') + '0')
                } else {
                    return v * 100
                }
            } else
                return v * 100
        }
    }
}