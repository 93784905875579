<template>
    <div>
        <div class="img-desc" v-text="remark" v-if="remark"></div>
        <el-image-viewer class="img-code" :on-close="closeImg" :url-list="paths" :infinite="false" 
        :preview-teleported="true" hide-on-click-modal
            fit="cover" @switch="switchImg" @close="closeImg" />
    </div>
</template>

<script>
    export default {
        name: 'eimgView',
        props: {
            src: String,
            desc: String
        },
        data() {
            return {
                paths: [], remark: ''
            }
        },
        created() {
            var arr = (this.src || '').split(',')
            var res = []
            for (let i = 0; i < arr.length; i++) {
                const t = arr[i];
                if (t && !t.startsWith('http'))
                    res.push(this.getPath(t))
                else
                    res.push(t)
            }
            this.paths = res
            this.switchImg(0)
        },
        methods: {
            switchImg(idx) {
                if (this.desc) {
                    this.remark = this.desc.formatModel({
                        index: idx + 1, total: this.paths.length
                    })
                }
            },
            closeImg() {
                this.$emit('close')
            }
        }
    };
</script>
<style scoped>
    .img-desc {
        position: absolute;
        left: 50%;
        top: 100px;
        background: red;
        z-index: 9999;
        color: yellow;
        padding: 5px 25px;
        transform: translateX(-50%);
    }
</style>