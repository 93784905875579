<template>
    <el-image :src="path" :preview-src-list="paths" :infinite="false" :preview-teleported="true" hide-on-click-modal
        fit="cover" @switch="switchImg" @close="closeImg" ref="elimg">
        <!-- <template #viewer>
            <div class="img-desc" v-text="remark" v-if="remark"></div>
        </template> -->
        <template #error>
            <div class="image-slot">
                <el-icon style="font-size: 40px;">
                    <icon-picture />
                </el-icon>
                <!-- <i class="iconfont icon-touxiang"></i> -->
                <!-- <el-icon>
                    <icon-picture />
                </el-icon> -->
            </div>
        </template>
    </el-image>
</template>

<script>
    // import { Picture as IconPicture } from '@element-plus/icons-vue'
    export default {
        name: 'eimg',
        props: {
            src: String,
            desc: String
        },
        // components: { IconPicture },
        data() {
            return {
                path: '', paths: [], remark: ''
            }
        },
        watch: {
            src(value) {
                this.init()
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                var arr = (this.src || '').split(',')
                var res = []
                for (let i = 0; i < arr.length; i++) {
                    const t = arr[i];
                    if (t && !t.startsWith('http'))
                        res.push(this.getPath(t))
                    else
                        res.push(t)
                }
                this.paths = res
                this.path = res[0]
                this.switchImg(0)
            },
            switchImg(idx) {
                if (this.desc) {
                    this.remark = this.desc.formatModel({
                        index: idx + 1, total: this.paths.length
                    })
                }
            },
            closeImg() {
                this.$emit('closeImg')
            }
        }
    };
</script>