<template>
	<el-button v-if="show" :type="type" :size="size" :link="link" @click="click" :loading="loading">
		<slot>
			<i :class="icon" v-if="icon"></i>
			<span style="margin-right: 6px;" v-if="label && icon"></span>
			<span v-text="label"></span>
		</slot>
	</el-button>
</template>

<script>
export default {
	name: 'ebtn',
	props: {
		icon: {
			type: String
		},
		label: {
			type: String
		},
		type: {
			type: String,
			default: 'primary'
		},
		link: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'default'
		},
		auth: ''
	},
	data() {
		return {
			show: false,
			loading: false
		};
	},
	created() {
		this.show = this.auth ? this.$hasAuth(this.auth) : true
	},
	methods: {
		click() {
			this.loading = true;
			this.$emit('action', this.end);
		},
		end() {
			this.loading = false;
		}
	}
};
</script>