import { createApp } from 'vue'
import App from './App.vue'
import common from './common/index'
import router from './common/router'
import store from './common/store'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './assets/css/index.scss';
import '@static/font/iconfont.css';

const app = createApp(App)
app.use(ElementPlus, { locale: zhCn })

// var domain = 'http://60.175.245.114:60008'
// var domain = 'http://60.175.245.114:60008'//wms
// var domain = 'http://60.175.245.114:60008/v2'//wms
// var domain = 'http://172.18.5.37:8018/api'//ht
// var domain = 'https://gybx-api.mutools.cc/mall'//mall
// var domain = 'https://gybx-api.mutools.cc'//todo
// var domain = 'http://localhost:5254'
var domain = 'http://localhost:5000'
app.use(common, {
    getPath(path, opt) {
        opt = opt || {}
        if (!path || path.startsWith('http')) return path || ''
        if (!path.startsWith('/')) path = '/' + path
        var url = domain + path
        if (opt.withToken) {
            return url + '?token=' + localStorage.getItem('token')
        }
        return url
    },
    replacePath(path) {
        if (!path || !path.startsWith('http')) return path
        return path.replace(domain, '')
    },
    tips: false,
    task: {
        count: 5,
        url: '/common/getResult'
    },
    upload: {
        img: {
            size: '800kb',
            url: '/file/img',
            getUrl: '/file/getImgChunk',
            saveUrl: '/file/imgChunk',
            accept: '.png,.jpg,.bmp,.gif'
        },
        video: {
            size: '500Mb',
            saveUrl: '/file/videoChunk',
            getUrl: '/file/getVideoChunk',
            accept: '.mp4'
        },
        import: {
            size: '30Mb',
            saveUrl: '/file/import',
            getUrl: '/file/getImportChunk',
            accept: '.xls,.xlsx,.et'
        },
        doc: {
            size: '800kb',
            accept: '.doc,.docx,.pdf'
        }
    },
})
app.use(store).use(router).mount('#app')