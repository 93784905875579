<template>
    <el-tag :type="type" disable-transitions>
        {{label}}
    </el-tag>
</template>

<script>
    import { ref, watch, getCurrentInstance } from "vue";
    export default {
        name: 'etag',
        props: {
            tags: Array,
            mode: '',
            label: ''
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const type = ref({})

            var arr = ['', 'danger', 'success', 'info', 'warning']
            //'success' | 'info' | 'warning' | 'danger' | ''
            // if (props.tags) {
            //     arr = props.tags
            // } else {
            //     arr = [{ label: '是', value: true }, { type: 'danger', label: '否', value: false }]
            // }
            const get = (p) => {
                // if (proxy.$isFunc(props.config)) {
                //     opt.value = props.config(p.row)
                // }
                // var t = arr.filter(c => c.value == p.label || c.label == p.label)[0]
                // if (t) opt.value = t;
                // else opt.value = { label: p.label }

                var idx;
                if (proxy.mode) {
                    idx = proxy.mode
                } else {
                    var tags = proxy.$isStr(proxy.tags) ? proxy.tags.split(',') : (proxy.tags || []);
                    if (tags.length == 1) {
                        idx = proxy.label == tags[0] ? 2 : 1
                    } else {
                        idx = Math.max(0, tags.findIndex(c => c == proxy.label));
                    }
                }
                type.value = arr[idx]
            }
            get(props)
            watch(props, (newValue, oldValue) => {
                get(newValue)
            });
            return { type }
        }
    };
</script>
<style scoped>

</style>