const getRoute = (data) => {
    function get(c) {
        if (!c.name) {
            c.name = (c.path||'').replaceAll('/', '')
        }
        let path = c.component || c.path
		if (path && path != '/') {
            // c.component = resolve => require(["../../pages/" + path], resolve);
            c.component = () => import(/* webpackChunkName: "[request]" */ `../../pages${path}`)
        }
        if (!c.meta) {
            c.meta = {}
        }
        c.meta.tab = true
    }

    let temp = []
    if (Array.isArray(data)) {
        temp = data
    } else {
        temp = [data]
    }
    temp.forEach(c => {
        get(c)
        if (c.children) {
            c.children.forEach(get);
        }
    });
    return data
}

const $copy = (obj) => {
    if (!obj) return obj
    if (typeof obj == 'object') return JSON.parse(JSON.stringify(obj))
    return obj
}
const $isObj = (obj) => {
    return typeof obj == 'object'
}
const $isArr = (obj) => {
    return Array.isArray(obj)
}
const $isEmpty = (obj) => {
    if (obj == undefined || obj == null || obj == '') {
        return true
    } else if ($isArr(obj)) {
        return obj.length == 0
    }
}
const $isFunc = (obj) => {
    return typeof obj == 'function'
}
const $isStr = (obj) => {
    return typeof obj == 'string'
}


export { getRoute, $copy, $isObj, $isEmpty, $isFunc, $isStr }