<template>
	<emodal :title="config.title" @submit="submit" v-model="show" :width="width" :disClose="disClose">
		<eformBase :fields="config.fields" :class="className" :values="config.values" ref="form" :btns="[]"></eformBase>
		<slot></slot>
	</emodal>
</template>

<script>
	import { ref } from "vue";
	export default {
		name: 'emodalForm',//弹层表单
		props: {
			value: { type: Boolean },
			config: { type: Object },
			disClose: {//不可关闭
				type: Boolean,
				default: false
			}
		},
		emits: ['submit'],
		setup(props, { emit }) {
			const form = ref(null)
			let show = ref(props.value)
			var col = props.config.col || 1
			let width = ref(400 * Math.min(col, 2) + 'px')
			let className = ref('form-col' + col)

			const submit = (cb) => {
				form.value.validate(valid => {
					if (valid) {
						let q = JSON.parse(JSON.stringify(form.value.model))
						for (const key in q) {
							if (Object.hasOwnProperty.call(q, key)) {
								if (Array.isArray(q[key])) {
									q[key] = q[key].join()
								}
							}
						}
						emit('submit', q, cb);
					} else cb();
				});
			}
			return { show, width, className, form, submit };
		}
	};
</script>