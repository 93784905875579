<template>
	<el-date-picker v-model="val" :clearable="false" :placeholder="placeholder" :type="type" :value-format="format"
		@change="setValue">
	</el-date-picker>
</template>

<script>
import { ref, watch, getCurrentInstance } from "vue";
export default {
	name: 'edate',
	props: {
		modelValue: {},
		config: {
			type: Object,
			default: {
				// type: '', 类型
				// format: '',格式化字符串
				// placeholder: '',
			}
		}
	},
	setup(props, { emit }) {
		const { proxy } = getCurrentInstance();
		let config = props.config || {};
		let type = config.type || 'date'
		let defaultF = {
			date: 'YYYY-MM-DD',
			time: 'HH:mm:ss',
			datetime: 'YYYY-MM-DD HH:mm:ss',
			month: 'YYYY-MM'
		}
		let format = defaultF[type]
		let placeholder = config.placeholder || '请选择'

		let val = ref()
		const formatValue = () => {
			var v = props.modelValue
			if (!props.modelValue) {//清空
				val.value = '';
			} else {
				val.value = v && v.format ? v.format(format) : v
			}
		}
		formatValue()
		watch(props, formatValue);

		const setValue = (value) => {
			proxy.$emit('update:modelValue', value);
			proxy.$emit('set', { value });
		}

		return { format, type, val, placeholder, setValue }
	}
}
</script>