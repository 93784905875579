export default {
	install(app) {
		app.config.globalProperties.$tips = function (msg, func) {
			// this.$message({
			// 	message: msg,
			// 	type: 'success',
			// 	duration:1000
			// });
			if (msg) {
				this.$notify({
					// title: 'Success',
					message: msg,
					// offset:60,
					// duration:2500,
					type: 'success',
				})
			}
			if (func) {
				setTimeout(func, 2500);
			}
		}

		// app.config.globalProperties.$tipsWithTo = function (msg, path) {
		// 	this.$tips(msg)
		// 	if (path) {
		// 		setTimeout(() => {
		// 			this.$to(path)
		// 		}, 3000)
		// 	}
		// }

		app.config.globalProperties.$err = function (msg, func) {
			msg = msg?.message || msg?.msg || msg || '系统繁忙，请稍后重试'
			// this.$message.error({
			// 	message: msg,
			// 	duration: 3000
			// });
			this.$notify({
				// title: 'Success',
				message: msg,
				type: 'error',
			})
			func && func()

			// this.$alert(msg, '提示', {
			// 	confirmButtonText: '确定',
			// 	// callback: action => { }
			// }).catch((res) => {});
		}

		app.config.globalProperties.$alert = function (msg, func, opt) {
			opt = opt || {}
			this.$confirm(msg, opt.title || '提示', {
				showClose: false,
				showCancelButton: false,
				confirmButtonText: opt.text || '确定',
				dangerouslyUseHTMLString: true,
				type: 'warning'
			}).then(func).catch(func)
		}
		app.config.globalProperties.$conf = function (msg, func, cancelFunc) {
			if (!msg) {
				func()
				return
			}
			cancelFunc = cancelFunc || function () { }
			this.$confirm(msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(func).catch(cancelFunc)
		}
	}
}
