<template>
	<el-select ref="sel" v-model="val" :multiple="multiple" :placeholder="config.placeholder" collapse-tags filterable
		:clearable="clearable" :disabled="disabled" @change="setValue" :remote="remote" :remote-method="loadData"
		:loading="loading" remote-show-suffix>
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
			<el-row :gutter="20">
				<el-col :span="colSize" v-for="col in cols" :key="col">{{ item[col] }}</el-col>
			</el-row>
		</el-option>
	</el-select>
</template>

<script>
	import { ref, watch, reactive, getCurrentInstance } from "vue";
	export default {
		name: 'eselect',
		props: {
			modelValue: {},
			config: {
				type: Object,
				default: {
					// data: [], 数据源
					// labelKey: '',显示字段名 默认label
					// valueKey: '',实际值字段名 默认value
					// url: '',请求地址
					// params: '',请求参数 object/func
					// filter:null,数据源过滤方法 func
					// multiple: false,是否多选
					// placeholder:'' 提示语
					// cols:[] 列
					// searchKey: '',搜索参数字段名 有值表示启用远程搜索 反之为本地搜索
					// disabled
				}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let options = ref([])
			let multiple = ref(false)
			let cols = ref([])
			let colSize = ref(24)
			let remote = ref(false);
			let loading = ref(false);
			let val = ref('');

			let config = props.config || {}
			let clearable = ref(config.clearable === false ? false : true)
			let disabled = ref(!!config.disabled)

			const formatValue = () => {
				var v = props.modelValue
				if (multiple.value) {
					if (proxy.$isEmpty(v)) {
						val.value = [];
					} else if (proxy.$isArr(v)) {
						val.value = v.map(c => { return c + '' });
					} else {
						val.value = (v + '').split(',');
					}
				} else {
					val.value = proxy.$isEmpty(v) ? '' : v + '';
				}
			}
			watch(props, formatValue);

			var url = config.url
			var params = config.params || {}
			if (config.dict) {
				url = '/common/dict'
				params.type = config.dict
			}
			const loadData = (query) => {
				if (!url) return;
				loading.value = true
				let q = proxy.$isFunc(params) ? params() : Object.assign({}, params, { [config.searchKey]: query })
				proxy.$post(url, q, ({ data }) => {
					formatData(data);
					loading.value = false
				});
			}
			const formatData = (data) => {
				data = data.items || data || []
				if (proxy.$isEmpty(data)) {
					options.value = [];
					return;
				}
				if (proxy.$isFunc(config.filter)) {
					data = config.filter(data);
				}

				if (!data || !data.forEach) {
					data = []
				}

				data.forEach(item => {
					item.label = (item[config.labelKey || 'label'] || item.title)
					item.value = (item[config.valueKey || 'id'] || item.value) + ''
				});
				options.value = data;

				formatValue()
			}
			const setValue = (value) => {
				emit('update:modelValue', value);

				var id = proxy.$isArr(value) ? value[0] : value
				var row = options.value.filter(c => c.id == id)[0]
				row = row ? proxy.$copy(row) : {}
				emit('set', { value: id, row });
			}
			(function () {
				multiple.value = !!config.multiple;
				cols.value = config.cols || [config.labelKey || 'label']
				colSize.value = 24 / (cols.value.length)
				remote.value = !!config.searchKey
				if (url) {
					loadData();
				} else {
					formatData(config.data);
				}
			})()

			return { options, clearable, disabled, multiple, cols, colSize, remote, loading, val, loadData, formatData, setValue }
		},
	};
</script>