<template>
	<el-form ref="formRef" :model="model" :rules="rules" :label-width="labelWidth" :label-position="labelPosition"
		:label-suffix="labelSuffix" :inline="inline" :size="size" @submit.native.prevent style="padding-right: 10px;"
		:class="class">
		<slot></slot>
	</el-form>
</template>

<script>
	import { ref, watch, getCurrentInstance } from "vue";
	export default {
		name: 'eform',
		props: {
			modelValue: {},
			labelWidth: {
				type: String,
				default: '115px'
			},
			labelPosition: {
				type: String,
				default: 'right'
			},
			labelSuffix: {
				type: String,
				default: '：'
			},
			inline: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: ''
			},
			class: {
				type: String,
				default: ''
			},
			rule: {//校验
				type: Object,
				default: {}
			}
		},
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let formRef = ref(null)
			let rules = ref({})
			let model = ref(proxy.modelValue || {})
			watch(props, (newValue, oldValue) => {
				model.value = newValue.modelValue
			});

			const formValidator = (rule, value, callback) => {
				value = model.value[rule.field]
				let r = proxy.rule[rule.field]
				if (!r) return callback();

				if (proxy.$isEmpty(value)) {
					if (r.required) {
						let required = proxy.$isFunc(r.required) ? r.required(proxy.$copy(model.value)) : r.required

						let requiredMsg
						if (proxy.$isStr(required)) {// {required:'请输入'}
							requiredMsg = required
							required = true
						}

						if (required) {
							requiredMsg = requiredMsg || r.requiredMsg || r.msg || '请输入'
							return callback(new Error(requiredMsg));
						}
					}
					return callback();
				}

				if (r.min || r.max) {
					if (r.type == 'number') {
						if (!RegExp.const.number.reg.test(value)) {
							return callback(new Error(r.msg || RegExp.const.number.msg));
						} else if (r.min && r.max) {
							if (r.min > value || r.max < value) {
								return callback(new Error(`应为${r.min}-${r.max}之间`));
							}
						} else if (r.min && r.min > value) {
							return callback(new Error(`不能小于${r.min}`));
						} else if (r.max && r.max < value) {
							return callback(new Error(`不能大于${r.max}`));
						}
					} else {
						let str = value + ''
						if (r.min > str.length || r.max < str.length) {
							return callback(new Error(r.min && r.max ? `应为${r.min}-${r.max}个字符` : `最多支持${r.max}个字符`));
						}
					}
				}

				if (r.reg) {
					let reg;
					let regMsg;
					if (proxy.$isObj(r.reg)) {//{reg:{reg://,msg:''}}
						reg = r.reg.reg;
						regMsg = r.reg.msg;
					} else if (proxy.$isStr(r.reg)) {
						var _reg = RegExp.const[r.reg]
						if (_reg) {
							reg = _reg.reg
							regMsg = _reg.msg
						}
					}
					reg = reg || r.reg

					if (reg) {
						reg = proxy.$isStr(reg) ? new RegExp(reg) : reg;
						let message = regMsg || r.msg || '格式不正确';
						if (!reg.test(value)) {
							return callback(new Error(message));
						}
					}
				}
				callback();
			}
			for (const key in proxy.rule) {
				if (Object.hasOwnProperty.call(proxy.rule, key)) {
					const r = proxy.rule[key];
					var arr = [{ validator: formValidator, trigger: 'change' }]
					if (r.required) {
						arr.push({ required: true, message: r.requiredMsg || r.msg || '请输入', trigger: 'change' })
					}
					rules.value[key] = arr
				}
			}
			const validateField = (field) => {
				formRef.value.validateField(field, v => { })
			}
			const validate = (func) => {
				try {
					formRef.value.validate(func);
				} catch (error) {
					console.error(error);
				}
			}
			const submit = (cb, func) => {
				validate(valid => {
					if (valid) {
						var q = proxy.$copy(model.value)
						func(q)
					} else {
						cb()
					}
				});
			}
			return { rules, model, validateField, submit, formRef };
		}
	};
</script>