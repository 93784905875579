<template>
	<el-time-select v-model="val" clearable :placeholder="config.placeholder" :picker-options="config.options"
		@change="setValue">
	</el-time-select>
</template>

<script>
	export default {
		name: 'etime',
		props: {
			value: {},
			config: {
				type: Object,
				default: {
					// options: {},参数
					// placeholder: '',
				}
			}
		},
		data() {
			return {
				val: '',
			};
		},
		created() {
			this.init();
		},
		watch: {
			value(value) {
				if (!value) {
					this.val = '';
				}
			}
		},
		methods: {
			init() {
			},
			setValue(value) {
				this.$emit('update:modelValue', value);
				this.$emit('change', { value: value });
			}
		}
	};
</script>